/* Main container for Section4 */
.section4-about-container {
    display: flex;
    flex-direction: column; /* Makes the content stack vertically */
    align-items: left; /* Aligns content to the center horizontally */
    background-color: white;
}

.about4-text {
    text-align: left;
    margin-left: 5%;
}

.section4-heading {
    color: var(--primary-color);
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-family: var(--font-parlight);
}

.section4-title {
    font-size: 2rem;
    font-weight: bold;
    font-family: var(--font-headers);
    line-height: 1.2;
    max-width: 75%;

}

.section4-description {
    margin-bottom: 60px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--secondary-color);
    max-width: 70%;
}

/* Person Cards */
.person-cardsy-container {
    margin-top: 0px;
    margin-bottom: 100px;
    display: flex;
    justify-content: left;
    gap: 40px;
    margin-left: 5%;
    margin-right: 5%;
}

.person-cardy {
    position: relative;
    width: 350px;
    height: 120px;
    perspective: 1000px;
}

.cardy-front {
    background-color: var(--background-color);
    justify-content: left;
    align-items: left;
}

.cardy-front, .cardy-back {
    position: absolute;
    backface-visibility: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.6s ease;
    width: 350px;
    height: 160px;
}

.cardy-front h5, .cardy-front p {
    color: var(--secondary-color);
    text-align: left;
    margin-left: 10%;
    /* padding: 20px; */
}

.cardy-front h5{
    font-family: var(--font-headers);
    margin-top: 8%;
    margin-bottom: 0px;
    font-size: 1.2em;
    text-decoration: none;
}

.cardy-front p {
    font-family: var(--font-paragraph);
    max-width: 80%;
    font-size: .75em;
    line-height: 1;
    text-align: justify;
}

.cardy-back {
    transform: rotateY(180deg);
    width: 100%;
}

.person-cardy:hover .cardy-front {
    transform: rotateY(180deg);
}

.person-cardy:hover .cardy-back {
    transform: rotateY(360deg);
}

.cardy-back img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}


@media (max-width: 1300px) {

}

@media (max-width: 1140px) {


    .about4-text {
        margin-left: 5%;
    }
    
    .section4-heading {
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
    }
    
    .section4-title {
        font-size: 2rem;
        line-height: 1.2;
        max-width: 75%;
    
    }
    
    .section4-description {
        margin-bottom: 60px;
        font-size: 1rem;
        line-height: 1.5;
        max-width: 80%;
    }
    
    /* Person Cards */
    .person-cardsy-container {
        margin-top: -20px;
        margin-bottom: 100px;
        gap: 30px;
        /* margin-left: 5%; */
        /* margin-right: 5%; */
    }
    
    .person-cardy {
        width: 310px;
        height: 100px;
    }
    
    
    .cardy-front, .cardy-back {
        border-radius: 10px;
        width: 310px;
        height: 160px;
    }
    
    .cardy-front h5, .cardy-front p {
        /* margin-left: 7%; */
    }
    
    .cardy-front h5{
        /* margin-top: 12px;
        margin-bottom: -5px;
        font-size: 1em; */
    }
    
    .cardy-front p {
        /* max-width: 70%; */
        font-size: .75em;
        line-height: 1;
    }
    
    .cardy-back img {
        border-radius: 10px;
    }
    
    
  
  }
  
@media (max-width: 1000px) {


    .about4-text {
        margin-left: 5%;
    }
    
    .section4-heading {
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
    }
    
    .section4-title {
        font-size: 1.6rem;
        line-height: 1.2;
        max-width: 75%;
    
    }
    
    .section4-description {
        margin-bottom: 60px;
        font-size: .9rem;
        line-height: 1.5;
        max-width: 70%;
    }
    
    /* Person Cards */
    .person-cardsy-container {
        margin-top: -20px;
        margin-bottom: 60px;
        margin-left: 180px;
        gap: 30px;
        flex-direction: column;
        align-items: left;
        /* margin-left: 5%; */
        /* margin-right: 5%; */
    }
    
    .person-cardy {
        width: 340px;
        height: 120px;
        margin-bottom: 5%;
    }
    
    
    .cardy-front, .cardy-back {
        border-radius: 10px;
        width: 340px;
        height: 160px;
    }
    
    .cardy-front h5, .cardy-front p {
        /* margin-left: 7%; */
    }
    
    .cardy-front h5{
        margin-top: 5%;
        /* margin-bottom: -5px; */
        font-size: 1em;
    }
    
    .cardy-front p {
        /* max-width: 70%; */
        font-size: .85em;
        line-height: 1;
    }
    
    .cardy-back img {
        border-radius: 10px;
    }
    
    
    
  }
  
@media (max-width: 460px) {
  
    .about4-text {
        margin-left: 5%;
    }
    
    .section4-heading {
        font-size: .8rem;
        letter-spacing: 0.1rem;
    }
    
    .section4-title {
        font-size: 1rem;
        max-width: 80%;
        line-height: 1.2;
    
    }
    
    .section4-description {
        margin-bottom: 60px;
        font-size: .75rem;
        line-height: 1.2;
        max-width: 90%;
    }
    
    /* Person Cards */
    .person-cardsy-container {
        margin-top: -20px;
        margin-bottom: 60px;
        margin-left: 0;
        gap: 46px;
        flex-direction: column;
        align-items: center;
        /* margin-left: 5%; */
        /* margin-right: 5%; */
    }
    
    .person-cardy {
        width: 340px;
        height: 140px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    
    
    .cardy-front, .cardy-back {
        border-radius: 10px;
        width: 320px;
        height: 140px;
    }
    
    .cardy-front h5, .cardy-front p {
        margin-left: 7%;
    }
    
    .cardy-front h5{
        /* margin-top: 32px; */
        margin-bottom: -5px;
        font-size: 1em;
    }
    
    .cardy-front p {
        max-width: 70%;
        font-size: .75em;
        line-height: 1;
    }
    
    .cardy-back img {
        border-radius: 10px;
    }
    
    
    
  }
  
