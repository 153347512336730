
/* Import fonts from external locations */
@import url('./fonts/Satoshi_Complete/Fonts/WEB/css/satoshi.css');
@import url('./fonts/Sentient_Complete/Fonts/WEB/css/sentient.css');
@import url('./fonts/Trend_Sans_One/MyWebfontsKit/MyWebfontsKit.css');

/* Universal Definitions */
:root {
  --primary-color: #EA0D1D;
  --primary-color-rgb: 237 28 36;
  --secondary-color: #030D26;
  --secondary-color-rgb: 13, 20, 38;
  --background-grey: #f2f6f9;
  --background-grey-rgb: 13, 1, 100;
  --background-grey2: #cccc;
  --background-grey2-rgb: 188, 190, 192;
  --background-color: #f8f8f8;
  --background-white-rbg: 255 255 255;
  --highlight-green: #9DC183;
  --highlight-red: #DA8383;
  --highlight-yellow: #f4e87c;
  --highlight-green-bright: #53B326;
  --highlight-red-bright: #E32942;
  --highlight-yellow-bright: #ffb700;
  --footer-background-color: #222;
  --font-color: #ffffff;
  --font-headers: 'TrendSansOne', sans-serif;
  --font-paragraph: 'Satoshi-Light', sans-serif;
  --font-parlight: 'Satoshi-Regular', sans-serif;
  --font-parmedium: 'Satoshi-Medium', sans-serif;
}

html, body, #root {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: var(--font-paragraph);
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.App-main {
  display: flex;
  flex-direction: column;
}