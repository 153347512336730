/* Main container for the section */

/* Header Styling */

.App-content-Contactsection1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 710px;
    /* background: 
      linear-gradient(
        to bottom left,
        rgba(var(--background-grey-rgb), 0.5) 0%,
        rgba(var(--background-grey-rgb), 0) 50%
      ), */
      /* var(--background-grey2); */
    background: var(--background-grey);
  }
  
  .Section1-headerc {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    font-weight: normal;
  }
    
  .App-navc {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .App-logoc {
    flex: 1;
    text-align: left;
    padding-left: 20px;
  }
  
  .App-logoc img {
    height: 35px;
    transition: opacity 0.3s, transform 0.3s ease;
  }
  
  .App-logoc img:hover {
    transform: scale(1.05);
  }
  
  .App-menuc {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }
  
  .App-menuc li {
    margin: 0 10px;
  }
  
  .App-menuc a {
    color: var(--secondary-color); 
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 20px;
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }
  
  .App-menuc a:hover {
    color: var(--secondary-color);
    transform: scale(1.85);
  }
  
  .Merch-download-signinc {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding-right: 20px;
  }
  
  .Downloadc {
    width: auto;
    height: 30px;
    transition: transform 0.3s ease;
  }
  
  .Downloadc:hover {
    transform: scale(1.05);
  }
  
  .Merch-iconc {
    background-color: var(--background-color);
    padding: 5px 10px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .Merch-iconc:hover {
    background-color: var(--background-grey2);
    transform: scale(1.05);
  }
  
  .Signinc {
    color: var(--secondary-color);
    text-decoration: underline;
    transition: transform 0.3s ease;
    font-family: var(--font-headers);
    font-size: .65em;
  }
  
  .Signinc:hover {
    transform: scale(1.05);
  }


/* Main container box for all content */

.Contact-topbox {
  width: 100%;
  display: flex;
  top: 0;
  margin-top: 15%;
}

.topbox-img img {
  width: 90%;
  z-index: 1;
  justify-content: top;
  background: none;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  border-radius: 5% / 10%;
  top: 0;
  margin-bottom: 10%;
}

.topbox-links {
  width: 86%;
  display: flex;
  justify-content: center;
  z-index: 2;
  position: absolute;
  gap: 18%;
  margin-top: 29.5%;
  margin-left: 7.25%;
}

.interactive-button {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.interactive-button:hover {
  transform: scale(1.05);
}

.button1 img, .button2 img {
  width: 100%;
  opacity: 0;
}

/* Main container for the section */
.Contact-form-section {
  width: 100%;
  background-color: var(--background-grey2);
  /* Remove text-align: center */
}

.Contact-form-section h2 {
  font-family: var(--font-headers);
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
  margin-top: 7%;
  margin-bottom: 7%;
  text-align: left;
  margin-left: 11%;
  text-decoration: none;
}

.Contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.Contact-form input,
.Contact-form textarea {
  width: 60%;
  padding: 15px;
  font-family: var(--font-paragraph);
  font-size: 1rem;
  background-color: #f9fbfd; /* Light grey background */
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
}

.Contact-form textarea {
  height: 150px;
  resize: none;
}

.Contact-form input::placeholder,
.Contact-form textarea::placeholder {
  color: #a5a5a5; /* Lighter color for placeholder */
  font-weight: 400;
}

.Contact-form-select {
  margin-right: 10%;
  width: 50%;
  padding: 15px 15px 15px 20px;
  font-family: var(--font-paragraph);
  font-size: 1rem;
  background-color: #f9fbfd;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  color: #a5a5a5;
  cursor: pointer;
}

.Contact-form-select option {
  font-family: var(--font-paragraph);
  color: var(--background-color);
}


.Contact-form button {
  padding: 12px 30px 12px 30px;
  font-family: var(--font-headers);
  font-size: 1rem;
  color: white;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 4%;
  margin-bottom: 4%;
}

.Contact-form button:hover {
  background-color: var(--primary-color);
}

.Contact-form-section p {
  margin-bottom: 10%;
}

@media (max-width: 1000px) {
  .App-content-Contactsection1 {
    width: 100%;
    margin: 0px auto;
  }

  /* App Menu - occupies 40% */
  .App-menuc {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }

  .App-menuc li {
    margin: 0 1px;
  }

  .App-menuc a {
    color: var(--secondary-color); /* Initially white text */
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 10px; /* Adjust padding as necessary for spacing */
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }

  .App-menuc a:hover {
    color: var(--secondary-color); /* Change text color on hover */
    transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
    /* margin-top: 100px; */
  }

  .Contact-form-section h2 {
    font-family: var(--font-headers);
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    margin-top: 7%;
    margin-bottom: 7%;
    text-align: left;
    margin-left: 11%;
  }
  
  .Contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .Contact-form input,
  .Contact-form textarea {
    width: 60%;
    font-family: var(--font-paragraph);
    font-size: 1rem;
    margin-bottom: 5px;
    background-color: #f9fbfd; /* Light grey background */
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  .Contact-form textarea {
    height: 150px;
    resize: none;
  }
  
  .Contact-form input::placeholder,
  .Contact-form textarea::placeholder {
    color: #a5a5a5; /* Lighter color for placeholder */
    font-weight: 400;
  }
  
  .Contact-form button {
    padding: 10px 30px 10px 30px;
    font-family: var(--font-headers);
    font-size: 1rem;
    color: white;
    background-color: var(--secondary-color);
    border: none;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  
  .Contact-form button:hover {
    background-color: var(--primary-color);
  }
  


}

@media (max-width: 768px) {
  
  .Section1-headerc {
    height: auto;
    display: none;
  }

  .App-logoc img {
    height: 35px;
  }

  .App-navc {
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .App-menuc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1px 0;
    color: var(--secondary-color);
  }

  .App-menuc li {
    margin: 1px 0;
  }

  .App-menuc a {
    padding: 5px 10px;
    font-size: 0.75rem;
    color: var(--secondary-color);
  }


  .Signinc {
    font-size: 0.65rem;
  }


  .Merch-download-signinc {
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    gap: 10px;
  }

  .Downloadc {
    width: auto;
    height: 25px;
    transition: transform 0.3s ease;
  }

  .Signinc {
    font-size: 0.65rem;
  }

  .Merch-iconc {
    margin-right: 15px;
    font-size: 0.9rem;
  }
  
  .Contact-form-section h2 {
    font-family: var(--font-headers);
    font-size: 1.75rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    margin-top: 7%;
    margin-bottom: 7%;
    text-align: left;
    margin-left: 11%;
  }
  

  .Contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .Contact-form input,
  .Contact-form textarea {
    width: 60%;
    font-family: var(--font-paragraph);
    font-size: .75rem;
    margin-bottom: 5px;
    background-color: #f9fbfd; /* Light grey background */
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  .Contact-form textarea {
    height: 150px;
    resize: none;
  }
  
  .Contact-form input::placeholder,
  .Contact-form textarea::placeholder {
    color: #a5a5a5; /* Lighter color for placeholder */
    font-weight: 400;
  }
  
  .Contact-form button {
    padding: 8px 20px 8px 20px;
    font-family: var(--font-headers);
    font-size: .75rem;
    color: white;
    background-color: var(--secondary-color);
    border: none;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  
  .Contact-form button:hover {
    background-color: var(--primary-color);
  }

}

@media (max-width: 460px) {

  .App-content-Contactsection1 {
    min-height: 400px;
  }

  .Section1-headerc {
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
  }

  .App-logoc {
    margin-left: 15px;
  }

  .App-logoc img {
    height: 25px;
  }

  .App-navc {
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .App-menuc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .App-menuc li {
    margin: -2px 0;
  }

  .App-menuc a {
    padding: 1px 1px;
    font-size: 0.55rem;
  }

  .Merch-download-signinc {
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    gap: 10px;
  }

  .Downloadc {
    width: auto;
    height: 15px;
    transition: transform 0.3s ease;
  }

  .Signinc {
    font-size: 0.45rem;
  }

  .Merch-iconc {
    font-size: 0.7rem;
  }



  .Contact-topbox {
    width: 100%;
    display: flex;
    top: 0;
    margin-top: 15%;
  }
  
  .topbox-img img {
    top: 0;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .topbox-links {
    width: 80%;
    display: flex;
    justify-content: center;
    z-index: 2;
    position: absolute;
    margin-top: 39.25%;
    margin-left: 8.5%;
    gap: 2%;
  }
  
  .button1 img, .button2 img {
    width: 100%;
    opacity: 0;
  }


  .Contact-form-section h2 {
    font-family: var(--font-headers);
    font-size: 1rem;
    color: var(--primary-color);
    margin-top: 7%;
    margin-bottom: 7%;
    text-align: left;
    margin-left: 5%;
  }
  

  .Contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .Contact-form input,
  .Contact-form textarea {
    width: 60%;
    font-family: var(--font-paragraph);
    font-size: .75rem;
    margin-bottom: 0px;
    background-color: #f9fbfd; /* Light grey background */
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  .Contact-form textarea {
    height: 150px;
    resize: none;
  }
  
  .Contact-form input::placeholder,
  .Contact-form textarea::placeholder {
    color: #a5a5a5; /* Lighter color for placeholder */
    font-weight: 400;
  }
  
  .Contact-form button {
    padding: 8px 20px 8px 20px;
    font-family: var(--font-headers);
    font-size: .75rem;
    color: white;
    background-color: var(--secondary-color);
    border: none;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 4%;
    margin-bottom: 8%;
  }
  
  .Contact-form button:hover {
    background-color: var(--primary-color);
  }
  

}