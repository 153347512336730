.S3-BEntry2-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background: linear-gradient(to bottom, rgba(13, 20, 28) 0%, white 1%);
    margin-top: 0px; /* Overlap Section1 slightly */
    z-index: 1;
  }
  
  /* Content container styling */
  .S3-BEntry2-content {
    margin-top: 5%;
    margin-bottom: 4%;
    display: flex;
    flex-direction: row;
    margin-left: 4%;
    margin-right: 4%;
    gap: 4%;
    text-align: left; /* Ensure all text aligns left */
  }
  
  /* Left and right column styling */
  .S3-BEntry2-left,
  .S3-BEntry2-right {
    width: 48%;
  }
  
  /* General styling for text, paragraphs justified */
  .S3-BEntry2-content p {
    font-family: var(--font-paragraph);
    color: var(--secondary-color);
    line-height: 1.1;
    margin-bottom: 20px;
    text-align: justify; /* Justified text alignment */
    font-size: 0.9rem;
  }
  
  .S3-BEntry2-content li {
    font-family: var(--font-parlight);
    color: var(--secondary-color);
    line-height: 0;
    margin-bottom: 20px;
    text-align: justify; /* Justified text alignment */
    font-size: 0.9rem;
  }
  

  .S3-BEntry2-heading1 {
    font-family: var(--font-headers);
    color: var(--secondary-color);
    font-size: 1.25rem;
    margin-bottom: 20px;
    margin-top: -4%;
  }
  /* Heading 1 styling */
  .S3-Bentry2-heading2,
  .S3-Bentry2-heading3,
  .S3-Bentry2-heading4,
  .S3-Bentry2-heading5 {
    font-family: var(--font-headers);
    color: var(--secondary-color);
    font-size: 1.25rem;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  
  /* Image styling for the first image */
  .S3-BEntry2-image {
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-bottom: 0px; /* Adjust bottom margin for this image */
    margin-top: 0px; /* Adjust top margin for this image */
  }
  
  /* Image styling for the second image */
  .S3-BEntry2-image2 {
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-bottom: 0px;
    margin-top: 0px; /* Different top margin for second image */
  }
  
  /* Styling for the modal overlay */
  .image-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Black background with transparency */
    z-index: 1000;
  }
  
  /* Expanded image styling */
  .expanded-image {
    max-width: 90%;
    max-height: 90%;
    transition: transform 0.3s ease;
  }
  
  /* Close button styling */
  .close-overlay {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 40px;
    color: white;
    cursor: pointer;
  }
  
  /* Unordered list styling */
  .S3-entry2-list1 {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 1000px) {
    .S3-BEntry2-content {
      margin-top: 10%;
      margin-bottom: 4%;
      margin-left: 4%;
      margin-right: 4%;
      gap: 4%;
    }
  }
  
  @media (max-width: 768px) {
    .S3-BEntry2-content {
      flex-direction: column;
    }

    .S3-BEntry2-left,
    .S3-BEntry2-right {
      width: 100%; /* Full width on smaller screens */
      padding: 0;
    }
  
    /* Content container styling */
    .S3-BEntry2-content {
      margin-top: 14%;
      margin-bottom: 4%;
      margin-left: 6%;
      margin-right: 6%;
      gap: 4%;
    }

    .S3-BEntry2-content p {
      line-height: 1.05;
      margin-bottom: 15px;
      font-size: 0.85rem;
    }
    
    .S3-BEntry2-content li {
      line-height: 0;
      margin-bottom: 20px;
      font-size: 0.85rem;
    }

    .S3-BEntry2-heading1 {
      font-size: 1.15rem;
      margin-bottom: 20px;
      margin-top: -4%;
    }

    .S3-Bentry2-heading2,
    .S3-Bentry2-heading3,
    .S3-Bentry2-heading4,
    .S3-Bentry2-heading5 {
      font-size: 1.15rem;
      margin-bottom: 20px;
      margin-top: 5%;
    }
    
    .S3-BEntry2-image {
      margin-bottom: -10px;
      margin-top: 0px;
    }
    
    .S3-BEntry2-image2 {
      margin-bottom: -20px;
      margin-top: 0px;
    }

    .S3-entry2-list1 {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 460px) {
    .S3-BEntry2-content {
      margin-top: 18%;
      margin-bottom: 4%;
      margin-left: 7%;
      margin-right: 7%;
      gap: 4%;
    }

    .S3-BEntry2-content p {
      line-height: 1.05;
      margin-bottom: 15px;
      font-size: 0.8rem;
    }
    
    .S3-BEntry2-content li {
      line-height: 1.05;
      margin-bottom: 5px;
      font-size: 0.8rem;
    }

    .S3-BEntry2-heading1 {
      font-size: 1rem;
      margin-bottom: 20px;
      margin-top: -4%;
    }

    .S3-Bentry2-heading2,
    .S3-Bentry2-heading3,
    .S3-Bentry2-heading4,
    .S3-Bentry2-heading5 {
      font-size: 1rem;
      margin-bottom: 20px;
      margin-top: 5%;
    }
    
    .S3-BEntry2-image {
      margin-bottom: -10px;
      margin-top: 0px;
    }
    
    .S3-BEntry2-image2 {
      margin-bottom: -20px;
      margin-top: 0px;
    }

    .S3-entry2-list1 {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
