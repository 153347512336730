/* Main container styling */
.Section2-BEntry1-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 40px 40px 0 0; /* Rounded top corners */
  margin-top: -340px; /* Overlap Section1 slightly */
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Content container styling */
.Section2-BEntry1-content {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  margin-left: 4%;
  margin-right: 4%;
  gap: 4%;
  text-align: left; /* Ensure all text aligns left */
}

/* Left and right column styling */
.Section2-BEntry1-left,
.Section2-BEntry1-right {
  width: 48%;
}

/* General styling for text, paragraphs justified */
.Section2-BEntry1-content p {
  font-family: var(--font-paragraph);
  color: white;
  line-height: 1.1;
  margin-bottom: 20px;
  text-align: justify; /* Justified text alignment */
  font-size: .9rem;
}

.Section2-BEntry1-content li {
  font-family: var(--font-parlight);
  color: white;
  line-height: 0;
  margin-bottom: 20px;
  text-align: justify; /* Justified text alignment */
  font-size: .9rem;
}

/* Heading 1 styling */
.Section2-BEntry1-heading1 {
  font-family: var(--font-headers);
  color: white;
  font-size: 1.25rem;
  margin-bottom: 20px;
  margin-top: -4%;
}

/* Heading 2 styling */
.Section2-Bentry1-heading2 {
  font-family: var(--font-headers);
  color: white;
  font-size: 1.25rem;
  margin-top: 50px;
  margin-bottom: 20px;
}

/* Image styling for the first image */
.BEntry1-image {
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 10px; /* Adjust bottom margin for this image */
  margin-top: -10px; /* Adjust top margin for this image */
}

/* Image styling for the second image */
.BEntry1-image2 {
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 70px;
  margin-top: 40px; /* Different top margin for second image */
}

/* Styling for the modal overlay */
.image-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with transparency */
  z-index: 1000;
}

/* Expanded image styling */
.expanded-image {
  max-width: 90%;
  max-height: 90%;
  transition: transform 0.3s ease;
}

/* Close button styling */
.close-overlay {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 40px;
  color: white;
  cursor: pointer;
}

/* Unordered list styling */
.entry1-blog-list1 {
  margin-left: 20px;
  margin-bottom: 20px;
}

/* Responsive design for mobile view */

@media (max-width: 1000px) {


  .Section2-BEntry1-container {
    margin-top: -410px; /* Overlap Section1 slightly */
  }

}

  @media (max-width: 768px) {

    .Section2-BEntry1-content {
      flex-direction: column;
    }
  
    .Section2-BEntry1-left,
    .Section2-BEntry1-right {
      width: 100%; /* Full width on smaller screens */
      padding: 0;
    }

    .Section2-BEntry1-container {
      margin-top: -430px; /* Overlap Section1 slightly */
    }
  
  /* Content container styling */
  .Section2-BEntry1-content {
    margin-top: 10%;
    margin-left: 6%;
    margin-right: 6%;
  }
  
  .Section2-BEntry1-content p {
    line-height: 1.05;
    margin-bottom: 0px;
    font-size: .85rem;
  }
  
  .Section2-BEntry1-content li {
    line-height: 0;
    margin-bottom: 20px;
    font-size: .85rem;
  }
  
  .Section2-BEntry1-heading1 {
    font-size: 1.15rem;
    margin-bottom: 20px;
    margin-top: -5%;
  }
  
  .Section2-Bentry1-heading2 {
    font-size: 1.15rem;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .BEntry1-image {
    margin-bottom: 10px;
    margin-top: -10px;
  }
  
  .BEntry1-image2 {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  
  .entry1-blog-list1 {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  }


  @media (max-width: 460px) {

    .Section2-BEntry1-container {
      margin-top: -430px; /* Overlap Section1 slightly */
    }
  
  /* Content container styling */
  .Section2-BEntry1-content {
    margin-top: 10%;
    margin-left: 7%;
    margin-right: 7%;
  }
  
  .Section2-BEntry1-content p {
    line-height: 1.05;
    margin-bottom: 0px;
    font-size: .8rem;
  }
  
  .Section2-BEntry1-content li {
    line-height: 0;
    margin-bottom: 20px;
    font-size: .8rem;
  }
  
  .Section2-BEntry1-heading1 {
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: -3%;
  }
  
  .Section2-Bentry1-heading2 {
    font-size: 1rem;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .BEntry1-image {
    margin-bottom: 10px;
    margin-top: 5px;
  }
  
  .BEntry1-image2 {
    margin-bottom: 40px;
    margin-top: 25px;
  }
  
  .entry1-blog-list1 {
    margin-left: 20px;
    margin-bottom: 20px;
  }

}