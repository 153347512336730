.App-footer {
  background-color: var(--secondary-color);
  color: var(--font-color);
  padding: 30px 60px;
  position: relative;
  max-height: 500px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.footer-logo {
  width: 275px;
  margin-bottom: 24px;
  margin-top: 20px;
}

.footer-menu {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
  font-family: var(--font-headers);
  font-size: .8rem;
  margin-bottom: 8px;
}

.footer-left p {
  font-size: 1.25em;
  font-family: var(--font-parmedium);
  margin-bottom: -5px;
}

.footer-menu a {
  color: var(--font-color);
  text-decoration: none;
}

.social-icons {
  list-style: none;
  display: flex;
  gap: 12px;
  margin: 0;
  padding: 0;
}

.social-icons img {
  width: 35px;
  height: 35px;
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.footer-right p {
  margin: 0;
  font-size: 1.25rem;
  font-family: var(--font-parmedium);
  margin-bottom: 5px;
}

.app-links {
  display: flex;
  gap: 10px;
  margin-bottom: 100px;
}

.app-store-apple {
  width: 100px;
  height: auto;
}

.app-store-google {
  width: 110px;
  height: auto;
}

.footer-right .footer-copyright {
  font-size: 1rem;
  margin-top: 20px;
}


@media (max-width: 1065px) {

  .App-footer {
    max-height: 250px;
  }
  
  .footer-content {

  }
  
  .footer-left {
    gap: 15px;
  }
  
  .footer-logo {
    width: 250px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .footer-menu {
    gap: 35px;
    margin: 0;
    padding: 0;
    font-family: var(--font-headers);
    font-size: .8rem;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .footer-left p {
    font-size: 1.1em;
    font-family: var(--font-parmedium);
    margin-bottom: -5px;
  }
  
  .footer-menu a {
    color: var(--font-color);
    text-decoration: none;
  }
  
  .social-icons {
    list-style: none;
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
  }
  
  .social-icons img {
    width: 30px;
    height: 30px;
  }
  
  .footer-right {
    gap: 8px;
  }
  
  .footer-right p {
    margin: 0;
    font-size: 1.1rem;
    font-family: var(--font-parmedium);
    margin-bottom: 5px;
  }
  
  .app-links {
    gap: 10px;
    margin-bottom: 80px;
  }
  
  .app-store-apple {
    width: 100px;
    height: auto;
  }
  
  .app-store-google {
    width: 110px;
    height: auto;
  }
  
  .footer-right .footer-copyright {
    font-size: .9rem;
    margin-top: 30px;
  }

}


@media (max-width: 926px) {

  .App-footer {
    max-height: 250px;
  }
  
  .footer-left {
    gap: 15px;
  }
  
  .footer-logo {
    width: 250px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .footer-menu {
    gap: 25px;
    margin: 0;
    padding: 0;
    font-family: var(--font-headers);
    font-size: .8rem;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .footer-left p {
    font-size: 1em;
    font-family: var(--font-parmedium);
    margin-bottom: -5px;
  }
  
  .footer-menu a {
    color: var(--font-color);
    text-decoration: none;
  }
  
  .social-icons {
    list-style: none;
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
  }
  
  .social-icons img {
    width: 30px;
    height: 30px;
  }
  
  .footer-right {
    gap: 8px;
  }
  
  .footer-right p {
    margin: 0;
    font-size: 1rem;
    font-family: var(--font-parmedium);
    margin-bottom: 5px;
  }
  
  .app-links {
    gap: 10px;
    margin-bottom: 80px;
  }
  
  .app-store-apple {
    width: 100px;
    height: auto;
  }
  
  .app-store-google {
    width: 110px;
    height: auto;
  }
  
  .footer-right .footer-copyright {
    font-size: .7rem;
    margin-top: 30px;
  }
 
}


@media (max-width: 802px) {


  .App-footer {
    max-height: 250px;
  }
  
  .footer-left {
    gap: 15px;
  }
  
  .footer-logo {
    width: 250px;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  
  .footer-menu {
    gap: 25px;
    margin: 0;
    padding: 0;
    font-family: var(--font-headers);
    font-size: .7rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .footer-left p {
    font-size: .9em;
    font-family: var(--font-parmedium);
    margin-bottom: -5px;
  }
  
  .footer-menu a {
    color: var(--font-color);
    text-decoration: none;
  }
  
  .social-icons {
    list-style: none;
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
  }
  
  .social-icons img {
    width: 30px;
    height: 30px;
  }
  
  .footer-right {
    gap: 8px;
  }
  
  .footer-right p {
    margin: 0;
    font-size: .9rem;
    font-family: var(--font-parmedium);
    margin-bottom: 5px;
  }
  
  .app-links {
    gap: 10px;
    margin-bottom: 80px;
  }
  
  .app-store-apple {
    width: 80px;
    height: auto;
  }
  
  .app-store-google {
    width: 88px;
    height: auto;
  }
  
  .footer-right .footer-copyright {
    font-size: .5rem;
    margin-top: 20px;
  }
 

}

@media (max-width: 768px) {

      .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        overflow: hidden;
      }
    
      .footer-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    
      .footer-logo {
        width: 200px;
        margin-left: -60%;
        margin-bottom: -40px;
        margin-top: 0px;
      }
    
      .footer-menu {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    
      .footer-menu a {
        font-size: .75rem;
      }
    

      .footer-left p {
        font-size: 1em;
        font-family: var(--font-parmedium);
        margin-top: -90px;
        margin-left: -60%;

      }

      .social-icons {
        gap: 10px;
        margin-left: -55%;
        margin-top: 0px;
      }
    
      .social-icons img {
        width: 30px;
        height: 30px;
      }
    
      .footer-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 1px;
        margin-right: -60%;
        margin-top: -95px
      }
    
      .app-links {
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-bottom: 0px;
      }
    
      .app-store-apple,
      .app-store-google {
        width: 100px;
        height: auto;
      }
    
      .footer-copyright {
        font-size: 1rem;
        margin-top: 20px;
      }
    
}

@media (max-width: 674px) {

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    overflow: hidden;
    min-height: 120px;
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footer-logo {
    width: 150px;
    margin-left: -67%;
    margin-bottom: -40px;
    margin-top: 0px;
  }

  .footer-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
  }

  .footer-menu a {
    font-size: .65rem;
  }


  .footer-left p {
    font-size: .75em;
    font-family: var(--font-parmedium);
    margin-top: -80px;
    margin-left: -68%;

  }

  .footer-right p {
    font-size: .5;
  }

  .social-icons {
    gap: 10px;
    margin-left: -64%;
    margin-top: 0px;
  }

  .social-icons img {
    width: 20px;
    height: 20px;
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-right: -60%;
    margin-top: -95px
  }

  .app-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 0px;
  }

  .app-store-apple,
  .app-store-google {
    width: 80px;
    height: auto;
  }

  .footer-copyright {
    font-size: .25rem;
    margin-top: 50px;
  }


}

@media (max-width: 560px) {

      .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center all items horizontally */
        text-align: center;
        width: 100%;
        gap: 0px;
      }
    
      .footer-left {
        align-items: center; /* Center all items in the left section */
        text-align: center;
        width: 100%;
        margin-bottom: 0px; /* Add spacing below this section */
      }
    
      .footer-logo {
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
        width: 200px; /* Adjusted size for smaller screens */
      }
    
      .footer-menu {
        display: flex;
        flex-direction: column; /* Stack menu items vertically */
        gap: 2px; /* Increased spacing between menu items */
        margin: 0;
        text-align: center;
      }
    
      .footer-left p {
        font-size: .75em;
        font-family: var(--font-parmedium);
        margin-top: 0px;
        margin-left: 0px;
    
      }

      .social-icons {
        display: flex;
        justify-content: center;
        margin-left: 0px;
        margin-bottom: 5px;
      }
    
      .footer-right {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center items in the right section */
        width: 100%;
        text-align: center;
        margin-right: 0px;
        margin-top: 0px
      }
    
      .app-links {
        display: flex;
        flex-direction: row; /* Stack app links vertically */
        align-items: center;
        margin-top: 1px;
       }
    
      .app-store-apple,
      .app-store-google {
        width: 100px; /* Adjusted size for smaller screens */
        height: auto;
        margin-bottom: -10px;
      }
    
      .footer-right p,
      .footer-copyright {
        font-size: .75rem; /* Adjust font size for smaller screens */
        margin-top: 0px;
      }
  


}