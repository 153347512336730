/* Main container for Section3 */
.section3-about-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: white;
}

/* Ensure both sections take up 50% of the screen width */
.about-text, .feature-cards-container {
    width: 50%; /* Each section takes up 50% of the width */
    /* box-sizing: border-box; */
}

.about-text {
    margin-top: 40px;
}

/* For about-text: Ensure text is aligned left and there's no extra margin */
.about-text {
    text-align: left;
    margin-left: 5%;
}

/* Adjust the heading, title, and description styles */
.section-heading {
    color: var(--primary-color);
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-family: var(--font-parlight);
}

.section-title {
    font-size: 2rem;
    font-family: var(--font-headers);
    max-width: 100%;
    line-height: 1.2;
    color: var(--secondary-color)
}

.section-description {
    margin-bottom: 40px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--secondary-color);
}

.our-story-button {
    display: inline-block;
    padding: 14px 20px 10px 20px;
    border: 2px solid var(--secondary-color);
    border-radius: 25px;
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: bold;
    font-family: var(--font-headers);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.our-story-button:hover {
    background-color: var(--secondary-color);
    color: white;
}


/* Feature Cards */
.feature-cards-container {
    display: flex;
    flex-direction: column;
}

/* Top and Bottom Row */
.card-top-row, .card-bottom-row {
    display: flex;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%
}

/* Card styling */
.card {
    position: relative;
    width: 240px; /* Updated width */
    height: 180px; /* Updated height */
    perspective: 1000px; /* Ensure 3D perspective for flipping */
    border-radius: 10px;
    transition: transform 0.6s ease;
    margin-right: 5%;
}

/* Front and Back of the card */
.card-front, .card-back {
    position: absolute;
    width: 240px;
    height: 180px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    backface-visibility: hidden;
    transition: transform 0.6s ease;
}

/* Front of the card */
.card-front {
    background-color: var(--secondary-color);
    color: white;
    text-align: left;
}


/* Checkmark symbol */

.checkmark-image, .card-front h5, .card-front p {
    margin-left: 20px;
    margin-right: 20px;
}

/* Styling for the checkmark image */
.checkmark-image {
    height: 20px; /* Adjust size as needed */
    width: auto;
    margin-top: 15px;
}


.card-front h5 {
    font-family: var(--font-headers)
}

.card-front p {
    font-family: var(--font-paragraph);
    line-height: 1;
    color: white;
    font-size: .85em;
}



/* Back of the card with images */
.card-back {
    transform: rotateY(180deg);
    background-color: white;
}

.card:hover .card-front {
    transform: rotateY(180deg);
}

.card:hover .card-back {
    transform: rotateY(360deg);
}

/* Ensure images fit within the card properly */
.card-back img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}


@media (max-width: 1300px) {

    /* Main container for Section3 */
    .section3-about-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .about-text, .feature-cards-container {
        width: 50%;
    }

    .about-text {
        text-align: left;
        margin-left: 5%;
        margin-top: 40px;
    }

    /* Adjust the heading, title, and description styles */
    .section-heading {
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
    }

    .section-title {
        font-size: 2rem;
        font-family: var(--font-headers);
        max-width: 100%;
        line-height: 1.2;
    }

    .section-description {
        margin-bottom: 40px;
        font-size: 1rem;
        line-height: 1.5;
    }

    .our-story-button {
        padding: 14px 20px 10px 20px;
        border: 2px solid black;
        border-radius: 25px;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Top and Bottom Row */

    .card-top-row {
        margin-top: 35px;
    }
    .card-top-row, .card-bottom-row {
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 0%
    }

    /* Card styling */
    .card {
        position: relative;
        /* width: 200px;
        height: 160px; */
        border-radius: 10px;
        margin-right: 5%;
    }

    /* Front and Back of the card */
    .card-front, .card-back {
        width: 200px;
        height: 150px;
        border-radius: 10px;
    }

    /* Front of the card */
    .card-front {
        text-align: left;
    }

    .checkmark-image, .card-front h5, .card-front p {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: -5px;
    }

    .checkmark-image {
        height: 20px;
        margin-top: 20px;
    }


    .card-front h5 {
        font-family: var(--font-headers)
    }

    .card-front p {
        line-height: 1;
    }

    .card-back img {
        border-radius: 10px;
    }


}

@media (max-width: 1090px) {

    /* Main container for Section3 */
    .section3-about-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .about-text, .feature-cards-container {
        width: 50%;
    }

    .about-text {
        text-align: left;
        margin-left: 5%;
        margin-top: 40px;
    }

    /* Adjust the heading, title, and description styles */
    .section-heading {
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
    }

    .section-title {
        font-size: 1.6rem;
        font-family: var(--font-headers);
        max-width: 100%;
        line-height: 1.2;
    }

    .section-description {
        margin-bottom: 40px;
        font-size: .9rem;
        line-height: 1.5;
    }

    .our-story-button {
        padding: 14px 20px 10px 20px;
        border: 2px solid black;
        border-radius: 25px;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Top and Bottom Row */

    .card-top-row {
        margin-top: 35px;
    }
    .card-top-row, .card-bottom-row {
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 0%
    }

    /* Card styling */
    .card {
        position: relative;
        /* width: 200px;
        height: 160px; */
        border-radius: 10px;
        margin-right: 5%;
    }

    /* Front and Back of the card */
    .card-front, .card-back {
        width: 175px;
        height: 125px;
        border-radius: 10px;
    }

    /* Front of the card */
    .card-front {
        text-align: left;
    }

    .checkmark-image, .card-front h5, .card-front p {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: -5px;
    }

    .checkmark-image {
        height: 15px;
        margin-top: 20px;
    }


    .card-front h5 {
        font-family: var(--font-headers);
        font-size: .8em;
    }

    .card-front p {
        line-height: 1;
        font-size: .75em;
    }

    .card-back img {
        border-radius: 10px;
    }
  
  }
  
@media (max-width: 768px) {

    /* Main container for Section3 */
    .section3-about-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .about-text, .feature-cards-container {
        width: 40%;
    }

    .about-text {
        text-align: left;
        margin-left: 5%;
        margin-top: 40px;
    }

    /* Adjust the heading, title, and description styles */
    .section-heading {
        margin-top: 100px;
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
    }

    .section-title {
        font-size: 1.4rem;
        font-family: var(--font-headers);
        max-width: 100%;
        line-height: 1.2;
    }

    .section-description {
        margin-bottom: 40px;
        font-size: .85rem;
        line-height: 1.5;
    }

    .our-story-button {
        padding: 12px 17px 10px 18px;
        border: 2px solid black;
        border-radius: 25px;
        transition: background-color 0.3s ease, color 0.3s ease;
        font-size: .65rem;
    }

    /* Top and Bottom Row */

    .card-top-row {
        margin-top: 35px;
    }
    .card-top-row, .card-bottom-row {
        margin-left: 0%;
        margin-right: 0%;
        flex-direction: column;
    }

    /* Card styling */
    .card {
        position: relative;
        /* width: 200px;
        height: 160px; */
        border-radius: 10px;
        margin-left: 10%;
        margin-bottom: -45px;
    }

    /* Front and Back of the card */
    .card-front, .card-back {
        width: 175px;
        height: 125px;
        border-radius: 10px;
    }

    /* Front of the card */
    .card-front {
        text-align: left;
    }

    .checkmark-image, .card-front h5, .card-front p {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: -5px;
    }

    .checkmark-image {
        height: 18px;
        margin-top: 20px;
        margin-bottom: -15px;
    }


    .card-front h5 {
        font-family: var(--font-headers)
    }

    .card-front p {
        line-height: 1;
    }

    .card-back img {
        border-radius: 10px;
    }
    
  }
  
@media (max-width: 460px) {
  
 
    /* Main container for Section3 */
    .section3-about-container {
        margin-top: 50px;
        margin-bottom: 30px;
    }


    .about-text {
        width: 40%;
        margin-right: 20px;
    }

    .feature-cards-container {
        width: 60%;
    }

    .about-text {
        text-align: left;
        margin-left: 5%;
        margin-top: 0px;
    }

    /* Adjust the heading, title, and description styles */
    .section-heading {
        margin-top: 50px;
        font-size: .8rem;
        letter-spacing: 0.1rem;
    }

    .section-title {
        font-size: 1rem;
        font-family: var(--font-headers);
        max-width: 50%;
        line-height: 1.2;
    }

    .section-description {
        margin-bottom: 40px;
        font-size: .75rem;
        line-height: 1.2;
        max-width: 70%
    }

    .our-story-button {
        padding: 10px 15px 8px 16px;
        border: 2px solid black;
        border-radius: 25px;
        transition: background-color 0.3s ease, color 0.3s ease;
        font-size: .65rem;
    }

    /* Top and Bottom Row */

    .card-top-row {
        margin-top: 20px;
    }
    .card-top-row, .card-bottom-row {
        margin-left: 0%;
        margin-right: 0%;
        flex-direction: column;
        align-items: right;
    }

    /* Card styling */
    .card {
        position: relative;
        /* width: 200px;
        height: 160px; */
        /* padding-left: 10%; */
        border-radius: 10px;
        margin-bottom: -65px;
        margin-left: 0;
        /* margin-right: 0%;
        padding: 0; */
    }

    /* Front and Back of the card */
    .card-front, .card-back {
        width: 155px;
        height: 105px;
        border-radius: 10px;
    }

    /* Front of the card */
    .card-front {
        text-align: left;
    }

    .checkmark-image, .card-front h5, .card-front p {
        margin-left: 15px;
        margin-right: 0px;
        margin-bottom: -5px;
    }

    .checkmark-image {
        height: 18px;
        margin-top: 10px;
        margin-bottom: -15px;
    }


    .card-front h5 {
        font-family: var(--font-headers)
    }

    .card-front p {
        line-height: 1;
        font-size: .65rem;
    }

    .card-back img {
        border-radius: 10px;
    }
    
  }
  
