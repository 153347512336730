
.App-content-section5 {
  width: 100%;
  min-height: 400px;
  background: 
    linear-gradient(
      to bottom left,
      rgba(var(--secondary-color-rgb), 0.5) 0%,
      rgba(var(--secondary-color-rgb), 0) 50% 
    ),
    var(--primary-color);
  display: flex;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  z-index: 3;
}

.Section5-content {
  z-index: 3;
}

.Section5-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 3;
}

.Section5-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 60px;
  margin-top: 80px;
  margin-bottom: 40px;
  gap: 15px;
}

.Section5-headera,
.Section5-headerb {
  font-family: var(--font-headers);
  font-size: 3rem;
  color: var(--background-color);
  font-weight: normal;
  margin: 0;
}

.S5-contact-button {
  display: inline-block;
  padding: 11px 18px 10px 18px;
  background-color: white;
  border-radius: 25px;
  text-decoration: none;
  color: var(--primary-color);
  font-family: var(--font-headers);
  transition: background-color 0.3s ease, color 0.3s ease;
  max-width: fit-content;
  margin-top: 5%;
}

.S5-contact-button:hover {
  /* background-color: var(--secondary-color); */
  /* color: var(--secondary-color); */
  transform: scale(1.05);
}

.Section5-right {
  z-index: 3;
}

.Section5-screenshot {
  /* position: absolute; */
  align-items: flex-end;
  margin-right: 100px;
  margin-bottom: 0px;
  margin-top: 20px;
  width: 250px;
  z-index: 4;
}

@media (max-width: 1065px) {

  .App-content-section5 {
    width: 100%;
    min-height: 400px;
  }


  .Section5-header {
    margin-left: 60px;
    margin-top: 80px;
    margin-bottom: 40px;
    gap: 15px;
  }

  .Section5-headera,
  .Section5-headerb {
    font-size: 3rem;
    margin: 0;
  }

  .S5-contact-button {
    display: inline-block;
    padding: 9px 16px 8px 16px;
    background-color: white;
    border-radius: 25px;
    text-decoration: none;
    color: var(--primary-color);
    font-family: var(--font-headers);
    transition: background-color 0.3s ease, color 0.3s ease;
    max-width: fit-content;
    margin-top: 5%;
    font-size: .75rem;
  }

  .Section5-screenshot {
    margin-right: 100px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 250px;
  }
}

@media (max-width: 915px) {

  .App-content-section5 {
    width: 100%;
    min-height: 350px;
  }


  .Section5-header {
    margin-left: 60px;
    margin-top: 80px;
    margin-bottom: 40px;
    gap: 15px;
  }

  .Section5-headera,
  .Section5-headerb {
    font-size: 2.5rem;
    margin: 0;
  }

  .Section5-buttons {
    gap: 20px;
  }

  .Section5-butt {
    margin-left: 6px;
    font-size: .075rem;
  }

  .Section5-button {
    margin-left: 6px;
    padding: 10px 20px;
    border-radius: 40px;
    font-size: .75rem;
  }

  .Section5-screenshot {
    margin-right: 30px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 220px;
  }
}


@media (max-width: 795px) {

  .App-content-section5 {
    width: 100%;
    min-height: 330px;
  }


  .Section5-header {
    margin-left: 60px;
    margin-top: 80px;
    margin-bottom: 40px;
    gap: 15px;
  }

  .Section5-headera,
  .Section5-headerb {
    font-size: 2.25rem;
    margin: 0;
  }

  .Section5-buttons {
    gap: 20px;
  }

  .Section5-butt {
    margin-left: 6px;
    font-size: .075rem;
  }

  .Section5-button {
    margin-left: 6px;
    padding: 10px 20px;
    border-radius: 40px;
    font-size: .75rem;
  }

  .Section5-screenshot {
    margin-right: 30px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 200px;
  }
}

@media (max-width: 710px) {

  .App-content-section5 {
    width: 100%;
    min-height: 300px;
  }


  .Section5-header {
    margin-left: 50px;
    margin-top: 80px;
    margin-bottom: 40px;
    gap: 15px;
  }

  .Section5-headera,
  .Section5-headerb {
    font-size: 2.25rem;
    margin: 0;
  }

  .Section5-buttons {
    gap: 20px;
  }

  .Section5-butt {
    margin-left: 6px;
    font-size: .075rem;
  }

  .Section5-button {
    margin-left: 1px;
    padding: 7px 16px;
    border-radius: 40px;
    font-size: .55rem;
  }

  .Section5-screenshot {
    margin-right: 30px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 180px;
  }
}

@media (max-width: 616px) {

  .App-content-section5 {
    width: 100%;
    min-height: 270px;
  }


  .Section5-header {
    margin-left: 40px;
    margin-top: 60px;
    margin-bottom: 20px;
    gap: 15px;
  }

  .Section5-headera,
  .Section5-headerb {
    font-size: 2.25rem;
    margin: 0;
  }

  .S5-contact-button {
    display: inline-block;
    padding: 9px 16px 8px 16px;
    background-color: white;
    border-radius: 25px;
    text-decoration: none;
    color: var(--primary-color);
    font-family: var(--font-headers);
    transition: background-color 0.3s ease, color 0.3s ease;
    max-width: fit-content;
    margin-top: 5%;
    font-size: .7rem;
  }

  .Section5-screenshot {
    margin-right: 30px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 160px;
  }
}

@media (max-width: 540px) {

  .App-content-section5 {
    width: 100%;
    min-height: 240px;
  }


  .Section5-header {
    margin-left: 40px;
    margin-top: 60px;
    margin-bottom: 20px;
    gap: 5px;
  }

  .Section5-headera,
  .Section5-headerb {
    font-size: 2rem;
    margin: 0;
  }

  .Section5-buttons {
    gap: 10px;
  }

  .Section5-butt {
    margin-left: 5px;
    font-size: .05rem;
  }

  .Section5-button {
    margin-left: 1px;
    padding: 7px 16px;
    border-radius: 40px;
    font-size: .55rem;
  }

  .Section5-screenshot {
    margin-right: 5px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 130px;
  }

}

@media (max-width: 430px) {

  .App-content-section5 {
    width: 100%;
    min-height: 220px;
  }


  .Section5-header {
    margin-left: 20px;
    margin-top: 60px;
    margin-bottom: 20px;
    gap: 3px;
  }

  .Section5-headera,
  .Section5-headerb {
    font-size: 1.5rem;
    margin: 0;
  }

  .S5-contact-button {
    display: inline-block;
    padding: 7px 14px 6px 14px;
    background-color: white;
    border-radius: 25px;
    text-decoration: none;
    color: var(--primary-color);
    font-family: var(--font-headers);
    transition: background-color 0.3s ease, color 0.3s ease;
    max-width: fit-content;
    margin-top: 5%;
    font-size: .6rem;
  }


  .Section5-screenshot {
    margin-right: 5px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 130px;
  }

}