/* Main container for Section2 */
.section2-blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: lightgrey;
    border-radius: 40px 40px 0 0; /* Rounded top corners */
    margin-top: -60px; /* Overlap Section1 slightly */
    z-index: 1;
}

  .section2-blog-title {
    margin-top: 80px;
    margin-left: -65%;
    font-family: var(--font-headers);
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: left;
    align-items: flex-start;
  }
  
  .blog-posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;
    padding: 0 10%;
  }
  
  .blog-entry {
    flex-basis: calc(33.33% - 40px);
    gap: 20px;
    background-color: lightgrey;
    text-align: left;
    border: none;
  }
  
  .entry-image {
    width: 100%;
    height: auto;
    border-radius: 25px;
  }
  
  .entry-title {
    font-family: var(--font-parlight);
    font-size: 1.15rem;
    margin-top: 10px;
  }
  
  .entry-text {
    font-family: var(--font-paragraph);
    font-size: .8rem;

  }
  
  .author-entry-container {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
  }
  
  .author-entry-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .entry-image:hover {
    transform: scale(1.05); /* Slightly enlarge the image on hover */
  }

  .author-entry-description {
    font-family: var(--font-parmedium);
    font-size: 0.9rem;
  }
  .blog-button-container {
    display: flex;
    justify-content: center; /* Ensures the button is centered horizontally */
    align-items: center; /* Aligns items vertically in the container */
    width: 100%; /* Ensures it spans the full width */
}

.more-blogs-button {
    /* display: inline-block; */
    padding: 14px 20px 12px 20px;
    border: 2px solid var(--secondary-color);
    border-radius: 25px;
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: bold;
    font-family: var(--font-headers);
    transition: background-color 0.3s ease, color 0.3s ease;
    max-width: fit-content;
    margin-top: 50px;
    margin-bottom: 70px;
    align-items: center;
}


  .more-blogs-button:hover {
    background-color: var(--secondary-color);
    color: white;
  }


  @media (max-width: 4600px){

    .section2-blog-container {
        margin-top: 0px; /* Overlap Section1 slightly */
    }

    .section2-blog-title {
        font-size: 1rem;
      }
}

  @media (max-width: 1600px){

    .section2-blog-container {
        /* margin-top: -180px; */
        margin-top: -10%;
    }

    .section2-blog-title {
        font-size: 1rem;
      }

  }



  @media (max-width: 1000px) {

    .section2-blog-container {
        margin-top: -35%; /* Overlap Section1 slightly */
    }

    .section2-blog-title {
        font-size: 1rem;
      }


  }

    @media (max-width: 768px) {
  
        .section2-blog-container {
            border-radius: 35px 35px 0 0; /* Rounded top corners */
            margin-top: -62%; /* Overlap Section1 slightly */
        }
        
        .section2-blog-title {
        margin-top: 60px;
        margin-left: -65%;
        font-size: 1rem;
        margin-bottom: 20px;
        }
        
        .blog-posts-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        margin-top: 20px;
        padding: 0 5%;
        }
        
        .blog-entry {
        flex-basis: calc(33.33% - 40px);
        gap: 20px;
        background-color: lightgrey;
        text-align: left;
        border: none;
        }
        
        .entry-image {
        width: 100%;
        height: auto;
        border-radius: 15px;
        }
        
        .entry-title {
        font-family: var(--font-parlight);
        font-size: 1rem;
        margin-top: 5px;
        line-height: 1.15;
        }
        
        .entry-text {
        font-family: var(--font-paragraph);
        font-size: .7rem;
        line-height: 1.05;
        }
        
        .author-entry-container {
        display: flex;
        align-items: center;
        margin-bottom: 5%;
        }
        
        .author-entry-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        }
        
        .author-entry-description {
        font-family: var(--font-parmedium);
        font-size: 0.75rem;
        }
        .blog-button-container {
        display: flex;
        justify-content: center; /* Ensures the button is centered horizontally */
        align-items: center; /* Aligns items vertically in the container */
        width: 100%; /* Ensures it spans the full width */
    }
    
    .more-blogs-button {
        /* display: inline-block; */
        padding: 10px 16px 8px 16px;
        border: 2px solid var(--secondary-color);
        border-radius: 25px;
        text-decoration: none;
        color: var(--secondary-color);
        font-weight: bold;
        font-family: var(--font-headers);
        transition: background-color 0.3s ease, color 0.3s ease;
        max-width: fit-content;
        margin-top: 20px;
        margin-bottom: 50px;
        align-items: center;
        font-size: .7rem;
    }
  }

    @media (max-width: 610px) {
  
      .section2-blog-container {
          border-radius: 35px 35px 0 0; /* Rounded top corners */
          margin-top: -92%; /* Overlap Section1 slightly */
      }

    }


    @media (max-width: 460px) {
  
        .section2-blog-container {
            border-radius: 30px 30px 0 0; /* Rounded top corners */
            margin-top: -94%; /* Overlap Section1 slightly */
        }
        
        .section2-blog-title {
        margin-top: 30px;
        margin-left: -50%;
        font-size: .8rem;
        margin-bottom: 10px;
        }
        
        .blog-posts-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        margin-top: 20px;
        padding: 0 5%;
        }
        
        .blog-entry {
        flex-basis: calc(50% - 20px);
        gap: 10px;
        background-color: lightgrey;
        text-align: left;
        border: none;
        }
        
        .entry-image {
        width: 100%;
        height: auto;
        border-radius: 15px;
        }
        
        .entry-title {
        font-family: var(--font-parlight);
        font-size: .8rem;
        margin-top: 5px;
        line-height: 1.15;
        }
        
        .entry-text {
        font-family: var(--font-paragraph);
        font-size: .65rem;
        line-height: 1.05;
        }
        
        .author-entry-container {
        display: flex;
        align-items: center;
        margin-bottom: 5%;
        }
        
        .author-entry-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        }
        
        .author-entry-description {
        font-family: var(--font-parmedium);
        font-size: 0.65rem;
        }
        .blog-button-container {
        display: flex;
        justify-content: center; /* Ensures the button is centered horizontally */
        align-items: center; /* Aligns items vertically in the container */
        width: 100%; /* Ensures it spans the full width */
    }
    
    .more-blogs-button {
        /* display: inline-block; */
        padding: 8px 14px 6px 14px;
        border: 2px solid var(--secondary-color);
        border-radius: 25px;
        text-decoration: none;
        color: var(--secondary-color);
        font-weight: bold;
        font-family: var(--font-headers);
        transition: background-color 0.3s ease, color 0.3s ease;
        max-width: fit-content;
        margin-top: 20px;
        margin-bottom: 50px;
        align-items: center;
        font-size: .6rem;
    }
        


  }