/* Main container for the section */
.os1-App-content-OurStorysection1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 610px;
  position: relative;
  width: 100%;
}

/* Section1 Header styling */
.os1-Section1-headero {
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  font-weight: normal;
  z-index: 2;
}

.os1-App-navo {
  display: flex;
  align-items: center;
  width: 100%;
}

/* App Logo - occupies 30% */
.os1-App-logoo {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.os1-App-logoo img {
  height: 35px;
  transition: opacity 0.3s, transform 0.3s ease;
}

.os1-App-logoo img:hover {
  transform: scale(1.05);
}

/* App Menu - occupies 40% */
.os1-App-menuo {
  flex: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.os1-App-menuo li {
  margin: 0 10px;
}

.os1-App-menuo a {
  color: white;
  text-decoration: none;
  font-family: var(--font-headers);
  padding: 10px 20px;
  transition: color 0.3s, transform 0.3s;
  font-size: .65rem;
}

.os1-App-menuo a:hover {
  color: var(--secondary-color);
  transform: scale(1.85);
}

/* Merch, Download, Sign In - occupies 30% */
.os1-Merch-download-signino {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  padding-right: 20px;
}

.os1-Downloado {
  width: auto;
  height: 30px;
  transition: transform 0.3s ease;
}

.os1-Downloado:hover {
  transform: scale(1.05);
}

.os1-Merch-icono {
  background-color: var(--background-color);
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.os1-Merch-icono:hover {
  background-color: rgba(255, 255, 255, 0.7);
  transform: scale(1.05);
}

.os1-Signino {
  color: white;
  text-decoration: underline;
  transition: transform 0.3s ease;
  font-family: var(--font-headers);
  font-size: .65em;
}

.os1-Signino:hover {
  transform: scale(1.05);
}

/* Main container for Section1 */
.os1-App-content-section1-ourstory {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* Fullscreen background image */
.os1-Section1-ourstory-background .os1-story-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  opacity: .85;
  z-index: -1;
}

/* Content over the image */
.os1-Section1-ourstory-content {
  display: flex;
  position: absolute;
  color: white;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

/* Left-aligning the Page-title */
.os1-Page-title-os {
  margin-top: 14%;
  font-size: 4rem;
  font-family: var(--font-headers);
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10%;
  color: white;
}

/* Right-aligning the quote content */
.os1-quote-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: right;
  margin-right: 8%;
  max-width: 40%;
  margin-left: auto;
  color: white;
}

.os1-quote1, .os1-quote2, .os1-quote3, .os1-quote4 {
  font-size: 1.6rem;
  line-height: 1.55;
  margin-top: 0;
  margin-bottom: -10px;
  font-family: var(--font-parlight);
  font-style: italic;
  text-align: right;
  letter-spacing: 0.2rem;
  color: white;
}

.os1-quote-author {
  margin-top: 40px;
  font-size: .8rem;
  font-family: var(--font-headers);
  letter-spacing: 0.05em;
  text-align: right;
  color: white;
}

@media (max-width: 1000px) {
  .os1-App-content-OurStorysection1 {
    width: 100%;
    margin: 0px auto;
  }

  /* App Menu - occupies 40% */
  .os1-App-menuo {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }

  .os1-App-menuo li {
    margin: 0 1px;
  }

  .os1-App-menuo a {
    color: var(--font-color);
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 10px;
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }

  .os1-App-menuo a:hover {
    color: var(--secondary-color);
    transform: scale(1.85);
  }

  .os1-App-content-section1-ourstory {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  /* Left-aligning the Page-title */
  .os1-Page-title-os {
    font-size: 3rem;
  }
  
  .os1-quote1, .os1-quote2, .os1-quote3, .os1-quote4 {
    font-size: 1.4rem;
    line-height: 1.45;
    letter-spacing: 0.2rem;
  }
  
  .os1-quote-author {
    font-size: .75rem;
  }
}

@media (max-width: 768px) {
  .os1-Section1-headero {
    height: auto;
    display: none;
  }

  .os1-App-content-section1-ourstory {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .os1-Section1-ourstory-background .os1-story-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top;
    z-index: -1;
  }
  
  .os1-Section1-ourstory-content {
    display: flex;
    position: absolute;
    color: white;
    z-index: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  
  .os1-Page-title-os {
    margin-top: 14%;
    font-size: 2.5rem;
    font-family: var(--font-headers);
    margin-bottom: 10px;
    text-align: left;
    margin-left: 7%;
  }
  
  .os1-quote-content {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    text-align: right;
    margin-right: 5%;
    max-width: 60%;
    margin-left: auto;
  }
  
  .os1-quote1, .os1-quote2, .os1-quote3, .os1-quote4 {
    font-size: 1.3rem;
    line-height: 1.45;
    margin-top: 0;
    margin-bottom: -10px;
    font-family: var(--font-parlight);
    font-style: italic;
    text-align: right;
    letter-spacing: 0.2rem;
  }
  
  .os1-quote-author {
    margin-top: 40px;
    font-size: .75rem;
    font-family: var(--font-headers);
    letter-spacing: 0.05em;
    font-weight: bold;
    text-align: right;
  }
}


@media (max-width: 650px) {

.os1-Section1-ourstory-background .os1-story-image {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  z-index: -1;
}

.os1-Section1-ourstory-content {
  display: flex;
  position: absolute;
  color: white;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.os1-Page-title-os {
  margin-top: 16%;
  font-size: 2rem;
  font-family: var(--font-headers);
  margin-bottom: 10px;
  text-align: left;
  margin-left: 4%;
}

.os1-quote-content {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  text-align: right;
  margin-right: 5%;
  max-width: 90%;
  margin-left: auto;
}

.os1-quote1, .os1-quote2, .os1-quote3, .os1-quote4 {
  font-size: 1rem;
  line-height: 1.7;
  margin-top: 0;
  margin-bottom: -10px;
  font-family: var(--font-parlight);
  font-style: italic;
  text-align: right;
  letter-spacing: 0.2rem;
}

.os1-quote-author {
  margin-top: 20px;
  font-size: .6rem;
  font-family: var(--font-headers);
  letter-spacing: 0.05em;
  font-weight: bold;
  text-align: right;
}
}

@media (max-width: 460px) {

.os1-Section1-ourstory-background .os1-story-image {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  z-index: -1;
}

.os1-Section1-ourstory-content {
  display: flex;
  position: absolute;
  color: white;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.os1-Page-title-os {
  margin-top: 20%;
  font-size: 1.75rem;
  font-family: var(--font-headers);
  margin-bottom: 10px;
  text-align: left;
  margin-left: 4%;
}

.os1-quote-content {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  text-align: right;
  margin-right: 5%;
  max-width: 90%;
  margin-left: auto;
}

.os1-quote1, .os1-quote2, .os1-quote3, .os1-quote4 {
  font-size: .95rem;
  line-height: 1.7;
  margin-top: 0;
  margin-bottom: -10px;
  font-family: var(--font-parlight);
  font-style: italic;
  text-align: right;
  letter-spacing: 0.2rem;
}

.os1-quote-author {
  margin-top: 20px;
  font-size: .5rem;
  font-family: var(--font-headers);
  letter-spacing: 0.05em;
  font-weight: bold;
  text-align: right;
}
}
