/* Main container for the section */
.App-content-Blogsection1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 870px;
    position: relative;
    background: 
      linear-gradient(
        to bottom left,
        rgba(var(--background-grey-rgb), 0.5) 0%,  /* 50% transparency of secondary color starting from top right */
        rgba(var(--background-grey-rgb), 0) 50%    /* Gradually fade out to 0% opacity of secondary color by halfway */
      ),
      white;
    width: 100%;
    z-index: 0;
  }
  
  
  /* Section1 Header styling */
  .Section1-headerb {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    font-weight: normal;
    z-index: 2;
  }
    
  .App-navb {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  /* App Logo - occupies 30% */
  .App-logob {
    flex: 1;
    text-align: left;
    padding-left: 20px;
  }
  
  .App-logob img {
    height: 35px;
    transition: opacity 0.3s, transform 0.3s ease;
  }
  
  .App-logob img:hover {
    transform: scale(1.05);
  }
  
  /* App Menu - occupies 40% */
  .App-menub {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    color: var(--secondary-color);
  }
  
  .App-menub li {
    margin: 0 10px;
    color: var(--secondary-color);
  }
  
  .App-menub a {
    color: var(--secondary-color); /* Initially white text */
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 20px; /* Adjust padding as necessary for spacing */
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }
  
  .App-menub a:hover {
    color: var(--secondary-color); /* Change text color on hover */
    transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
  }
  
  /* Merch, Download, Sign In - occupies 30% */
  .Merch-download-signinb {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px; /* Spacing between the items */
    padding-right: 20px;
  }
  
  .Downloadb {
    width: auto;
    height: 30px; /* Set an appropriate height for the image */
    transition: transform 0.3s ease; /* Apply hover effect */
  }
  
  .Downloadb:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .Merch-iconb {
    background-color: var(--background-color);
    padding: 5px 10px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .Merch-iconb:hover {
    background-color: var(--background-grey2);
    transform: scale(1.05);
  }
  
  .Signinb {
    color: var(--secondary-color);
    text-decoration: underline;
    transition: transform 0.3s ease;
    font-family: var(--font-headers);
    font-size: .65em;
  }
  
  .Signinb:hover {
    transform: scale(1.05);
  }


  .App-content-section1-blog {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  /* Fullscreen background image */
  .Section1-blog-background .blog-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the entire image fits within the container */
    object-position: top; /* Aligns the image to the top */
    z-index: -1;
    opacity: 20%;
    margin: 0;
    padding: 0;
  }
  
  .Section1-blog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-left: 5%;
  }
  
  .Page-blog-title {
    font-size: 1.3rem;
    font-family: var(--font-headers);
    color: var(--primary-color);
    text-decoration: none;
    margin-top: 22%;
  }

  .blog-title-holder {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-top: 0px;
    margin-bottom: 0px;
  }

 /* Container holding both titles */
.blog-title-holder {
  display: flex;
  align-items: baseline; /* Aligns the two titles on the same baseline */
  line-height: 1.2;
}

/* Feature-title (this will not wrap) */
.Feature-title {
  white-space: nowrap; /* Prevents wrapping */
  margin-right: 15px;
  font-family: var(--font-parmedium);
  font-size: 2.2rem;
  color: var(--secondary-color);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

/* Feature-title2 (this will wrap if necessary) */
.Feature-title2 {
  flex: 1; /* Allows this element to take up remaining space */
  font-family: var(--font-paragaph);
  font-size: 2.2rem;
  color: var(--secondary-color);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  word-break: break-word; /* Ensures proper breaking of words when wrapping */
}

.Feature-title2::after{
  display: block;
  font-size: inherit;
  text-align: left;
}
  
  /* Feature Text */
  .Feature-text {
    margin-top: -5px;
    font-family: var(--font-parlight);
    font-size: 1rem;
    line-height: 1.25;
    color: var(--secondary-color);
    max-width: 90%;
    margin-bottom: 80px;
  }
  

  .feature-more-button {
    display: inline-block;
    padding: 11px 18px 10px 18px;
    border: 2px solid var(--secondary-color);
    border-radius: 25px;
    text-decoration: none;
    color: var(--secondary-color);
    font-family: var(--font-headers);
    transition: background-color 0.3s ease, color 0.3s ease;
    max-width: fit-content;
  }

  .feature-more-button:hover {
    background-color: var(--secondary-color);
    color: white;
  }


  @media (max-width: 5000px) {
    .Page-blog-title {
      margin-top: 10%;
    }

    .App-menub a {
      color: var(--secondary-color); /* Keep the color consistent */
    }
  }

  @media (max-width: 2000px) {
    .Page-blog-title {
      margin-top: 22%;
    }

    .App-menub a {
      color: var(--secondary-color); /* Keep the color consistent */
    }
  }

  @media (max-width: 1000px) {
    .App-content-Blogsection1 {
      width: 100%;
      margin: 0px auto;
    }
  
    /* App Menu - occupies 40% */
    .App-menub {
      flex: 2;
      display: flex;
      justify-content: center;
      padding: 0;
      list-style: none;
      color: var(--secondary-color);
    }
  
    .App-menub li {
      margin: 0 1px;
    }
  
    .App-menub a {
      color: var(--secondary-color); /* Initially white text */
      text-decoration: none;
      font-family: var(--font-headers);
      padding: 10px 10px; /* Adjust padding as necessary for spacing */
      transition: color 0.3s, transform 0.3s;
      font-size: .65rem;
    }
  
    .App-menub a:hover {
      color: var(--secondary-color); /* Change text color on hover */
      transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
      /* margin-top: 100px; */
    }
  


    .Section1-blog-content {
      margin-left: 5%;
    }
    
    .Page-blog-title {
      font-size: 1.1rem;
      margin-top: 18%;
    }
  
   /* Container holding both titles */
  .blog-title-holder {
    display: flex;
    align-items: baseline; /* Aligns the two titles on the same baseline */
    line-height: 1.2;
  }
  
  /* Feature-title (this will not wrap) */
  .Feature-title {
    white-space: nowrap;
    margin-right: 15px;
    font-size: 1.7rem;
    letter-spacing: 0.15rem;
  }
  
  .Feature-title2 {
    flex: 1;
    font-size: 1.7rem;
    letter-spacing: 0.15rem;
    word-break: break-word; /* Ensures proper breaking of words when wrapping */
  }
    
    /* Feature Text */
    .Feature-text {
      margin-top: -5px;
      font-size: 1rem;
      line-height: 1.15;
      max-width: 90%;
      margin-bottom: 60px;
    }
    
  
    .feature-more-button {
      padding: 12px 18px 8px 18px;
      border: 2px solid var(--secondary-color);
      border-radius: 25px;
      font-size: .8rem;
    }

  }

    @media (max-width: 768px) {
  
      .Section1-blog-background .blog-image {
        top: 60px;

      }

        .Section1-headerb {
          height: auto;
          display: none;
        }

        
        .App-menub a {
          color: var(--secondary-color); /* Keep the color consistent */
        }

        .Section1-blog-content {
          margin-left: 5%;
        }
        
        .Page-blog-title {
          font-size: 1rem;
          margin-top: 18%;
        }
      
       /* Container holding both titles */
      .blog-title-holder {
        display: flex;
        align-items: baseline; /* Aligns the two titles on the same baseline */
        line-height: 1.2;
      }
      
      /* Feature-title (this will not wrap) */
      .Feature-title {
        white-space: nowrap;
        margin-right: 15px;
        font-size: 1.25rem;
        letter-spacing: 0.15rem;
      }
      
      .Feature-title2 {
        flex: 1;
        font-size: 1.25rem;
        letter-spacing: 0.15rem;
        word-break: break-word; /* Ensures proper breaking of words when wrapping */
      }
        
        /* Feature Text */
        .Feature-text {
          margin-top: -5px;
          font-size: .9rem;
          line-height: 1.15;
          max-width: 90%;
          margin-bottom: 40px;
        }
        
      
        .feature-more-button {
          padding: 12px 18px 8px 18px;
          border: 2px solid var(--secondary-color);
          border-radius: 25px;
          font-size: .8rem;
        }


    }


    @media (max-width: 460px) {
  
      .Section1-blog-background .blog-image {
        top: 60px;

      }

      .Section1-headerb {
        height: auto;
        display: none;
      }

      .Section1-blog-content {
        margin-left: 5%;
        margin-right: 5%;
      }
      
      .Page-blog-title {
        font-size: .8rem;
        margin-top: 30%;
      }
    
      .App-menub a {
        color: var(--secondary-color); /* Keep the color consistent */
      }

     /* Container holding both titles */
    .blog-title-holder {
      display: flex;
      align-items: baseline; /* Aligns the two titles on the same baseline */
      line-height: 1;
    }
    
    /* Feature-title (this will not wrap) */
    .Feature-title {
      white-space: nowrap;
      margin-right: 15px;
      font-size: 1rem;
      letter-spacing: 0.1rem;
    }
    
    .Feature-title2 {
      flex: 1;
      font-size: 1rem;
      letter-spacing: 0.1rem;
      word-break: break-word; /* Ensures proper breaking of words when wrapping */
    }
      
      /* Feature Text */
      .Feature-text {
        margin-top: 5px;
        font-size: .8rem;
        line-height: 1.1;
        max-width: 97%;
        margin-bottom: 30px;
      }
      
    
      .feature-more-button {
        padding: 5px 16px 7px 16px;
        border: 2px solid var(--secondary-color);
        border-radius: 25px;
        font-size: .6rem;
        margin-top: -10px;
      }

  }