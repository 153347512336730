/* Main container for Section2 */
.section2-about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding-top: 50px;
}

/* Heading style */
.trusted-by {
    color: #e0e0e0;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    margin-bottom: 30px;
    flex-direction: row;
}

/* Logos container */
.logos-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px; /* Space between logos */
    max-width: 50%;
}

/* Individual logo items */
.logo-item {
    width: auto; /* Set a consistent width for the logos */
    height: 120px; /* Set a consistent height for the logos */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Slightly rounded corners */
    overflow: hidden;
    opacity: 40%;
}

/* Ensure logos are resized and centered within the containers */
.logo-item img {
    width: 100%; /* Make the logo fill the container */
    height: 100%;
    object-fit: contain; /* Keep the aspect ratio of the logo */
}

/* Adjusting for smaller screens */

@media (max-width: 1000px) {

    .logos-container {
        gap: 20px;
    }
    
    /* Individual logo items */
    .logo-item {
        width: 150px; /* Set a consistent width for the logos */
        height: 100px; /* Set a consistent height for the logos */
    }
    
  
  }
  
  @media (max-width: 768px) {
    .logos-container {
        gap: 30px;
    }
    
    /* Individual logo items */
    .logo-item {
        width: 80px; /* Set a consistent width for the logos */
        height: auto; /* Set a consistent height for the logos */
    }

  }
  
  @media (max-width: 460px) {
  
    .section2-about-container {
        padding-top: 20px;
    }

    .logos-container {
        gap: 20px;
    }
  
    .logo-item {
        width: 70px; /* Set a consistent width for the logos */
        height: auto; /* Set a consistent height for the logos */
    }

  }