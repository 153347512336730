/* Main container for Section 4 */
.App-content-section4 {
  width: 100%;
  min-height: 700px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* Header Styling */
.Section4-header {
  font-family: var(--font-headers);
  font-size: 2.25rem;
  color: var(--secondary-color);
  margin-top: 140px;
  margin-bottom: 25px;
  margin-right: 73%;
  z-index: 2;
  font-weight: normal;
}

/* FAQ Container */
.Section4-faq-container {
  width: 90%;
  /* max-width: 900px; */
  z-index: 2;
}

/* Individual FAQ Item */
.Section4-faq-item {
  margin-bottom: 22px;
  background-color: var(--background-grey);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Question Styling */
.Section4-question {
  font-family: var(--font-parmedium);
  font-size: 1.5rem;
  color: var(--secondary-color);
  padding: 12px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.Section4-question:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Arrow Styling */
.Section4-arrow {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.Section4-arrow.open {
  transform: rotate(180deg);
}

/* Answer Styling */
.Section4-answer {
  font-family: var(--font-paragraph);
  font-size: 1.15rem;
  color: var(--secondary-color);
  padding: 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--background-grey);
}


@media (max-width: 1000px) {

  
}


@media (max-width: 840px) {

  .App-content-section4 {
    min-height: 540px;
  }

  .Section4-header {
    font-size: 2rem;
    margin-top: 80px;
    margin-bottom: 50px;
  }


/* Question Styling */
.Section4-question {
  font-size: 1.45rem;
  padding: 10px 20px;
}

.Section4-arrow {
  font-size: 1.2rem;
}

/* Answer Styling */
.Section4-answer {
  font-size: 1rem;
}

}


@media (max-width: 705px) {

  .App-content-section4 {
    min-height: 540px;
  }

  .Section4-header {
    font-size: 1.8rem;
    margin-top: 80px;
    margin-bottom: 50px;
  }

/* Question Styling */
.Section4-question {
  font-size: 1.2rem;
  padding: 10px 20px;
}

.Section4-arrow {
  font-size: 1rem;
}

/* Answer Styling */
.Section4-answer {
  font-size: .9rem;
}

}

@media (max-width: 595px) {

  .App-content-section4 {
    min-height: 435px;
  }

  .Section4-header {
    font-size: 1.6rem;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .Section4-question {
    font-size: 1rem;
    padding: 10px 20px;
  }
  
  .Section4-arrow {
    font-size: .8rem;
  }
  
  /* Answer Styling */
  .Section4-answer {
    font-size: .8rem;
  }

}

@media (max-width: 550px) {

  .App-content-section4 {
    min-height: 390px;
  }

  .Section4-header {
    font-size: 1.4rem;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .Section4-question {
    font-size: .85rem;
    padding: 10px 20px;
  }
  
  .Section4-arrow {
    font-size: .7rem;
  }
  
  /* Answer Styling */
  .Section4-answer {
    font-size: .75rem;
  }

}

@media (max-width: 430px) {

  .App-content-section4 {
    min-height: 390px;
  }

  .Section4-header {
    font-size: 1.4rem;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .Section4-faq-container {
    width: 80%;
    max-width: 400px;
    z-index: 2;
  }
  
  /* Individual FAQ Item */
  .Section4-faq-item {
    margin-bottom: 2px;
    background-color: var(--background-grey);
    border-radius: 5px;
  }  

  .Section4-question {
    font-size: .65rem;
    padding: 10px 15px;
  }
  
  .Section4-arrow {
    font-size: .7rem;
  }
  
  /* Answer Styling */
  .Section4-answer {
    font-size: .75rem;
  }

}