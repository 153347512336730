/* Main container for Section 1b */
.App-content-section1b {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-color);
    width: 100%;
    z-index: 0;
  }
  
  /* Left side of Section 1b - Image */
  .Sec1b-left {
    flex: 1.2;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  
  .Section1b-img img {
    margin-top: 60px;
    margin-bottom: 60px;
    width: 500px;
    height: auto;
  }
  
  /* Right side of Section 1b - Text */
  .Sec1b-right {
    flex: 1;
    margin-right: 50px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--background-color);
  }
  
  /* Title 1 */
  .Sec1b-title1 {
    font-family: var(--font-headers);
    font-size: 3rem;
    color: var(--secondary-color);
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: left;
  }
  
  /* Title 2 */
  .Sec1b-title2 {
    font-family: var(--font-headers);
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
  }
  
  /* First text block */
  .Section1b-text1 {
    font-family: var(--font-parmedium);
    font-size: 1.25rem;
    color: var(--secondary-color);
    margin-bottom: 15px;
    line-height: 1.5;
    text-align: justify;
    margin-left: 5px;
  }
  
  /* Second text block */
  .Section1b-text2 {
    font-family: var(--font-paragraph);
    font-size: 1.1rem;
    color: var(--secondary-color);
    line-height: 1.25;
    text-align: justify;
    margin-bottom: 30px;
    margin-left: 5px;
  }
  
  .S1b-learn-more-button {
    display: inline-block;
    padding: 10px 16px 9px 16px;
    border: 2px solid var(--secondary-color);
    border-radius: 25px;
    text-decoration: none;
    color: var(--secondary-color);
    font-family: var(--font-headers);
    transition: background-color 0.3s ease, color 0.3s ease;
    max-width: fit-content;
    font-size: .75rem;
  }

  .S1b-learn-more-button:hover {
    background-color: var(--secondary-color);
    color: white;
  }


  
@media (max-width: 1000px) {
    .App-content-section1b {
    }

    .Sec1b-left {
    }

    .Section1b-img img {
      margin-top: 60px;
      margin-bottom: 60px;
      width: 350px;
    }

    /* Right side of Section 1b - Text */
    .Sec1b-right {
      margin-right: 50px;
      margin-left: 50px;
    }

    /* Title 1 */
    .Sec1b-title1 {
      font-family: var(--font-headers);
      font-size: 2.5rem;
      color: var(--secondary-color);
      margin-top: 40px;
      margin-bottom: 5px;
      font-weight: normal;
      text-align: left;
    }

    /* Title 2 */
    .Sec1b-title2 {
      font-family: var(--font-headers);
      font-size: 2.5rem;
      color: var(--primary-color);
      margin-bottom: 20px;
      font-weight: bold;
      text-align: left;
    }

    /* First text block */
    .Section1b-text1 {
      font-family: var(--font-parmedium);
      font-size: 1.15rem;
      color: var(--secondary-color);
      margin-bottom: 20px;
      line-height: 1.25;
      text-align: justify;
      margin-left: 5px;
    }

    /* Second text block */
    .Section1b-text2 {
      font-family: var(--font-paragraph);
      font-size: 1rem;
      color: var(--secondary-color);
      line-height: 1.25;
      text-align: justify;
      margin-bottom: 30px;
      margin-left: 5px;
    }


    .S1b-learn-more-button {
      display: inline-block;
      padding: 9px 15px 8px 15px;
      border: 2px solid var(--secondary-color);
      border-radius: 25px;
      text-decoration: none;
      color: var(--secondary-color);
      font-family: var(--font-headers);
      transition: background-color 0.3s ease, color 0.3s ease;
      max-width: fit-content;
      font-size: .75rem;
      margin-bottom: 4%;
    }
  }
  
  @media (max-width: 768px) {
   
    .App-content-section1b {
    }
    
    .Sec1b-left {
    }
    
    .Section1b-img img {
      margin-top: 60px;
      margin-bottom: 60px;
      width: 280px;
    }
    
    /* Right side of Section 1b - Text */
    .Sec1b-right {
      margin-right: 50px;
      margin-left: 50px;
    }
    
    /* Title 1 */
    .Sec1b-title1 {
      font-family: var(--font-headers);
      font-size: 2rem;
      color: var(--secondary-color);
      margin-top: 40px;
      margin-bottom: 5px;
      font-weight: normal;
      text-align: left;
    }
    
    /* Title 2 */
    .Sec1b-title2 {
      font-family: var(--font-headers);
      font-size: 2rem;
      color: var(--primary-color);
      margin-bottom: 20px;
      font-weight: bold;
      text-align: left;
    }
    
    /* First text block */
    .Section1b-text1 {
      font-family: var(--font-parmedium);
      font-size: 1.1rem;
      color: var(--secondary-color);
      margin-bottom: 20px;
      line-height: 1.2;
      text-align: justify;
      margin-left: 5px;
    }
    
    /* Second text block */
    .Section1b-text2 {
      font-family: var(--font-paragraph);
      font-size: .9rem;
      color: var(--secondary-color);
      line-height: 1.2;
      text-align: justify;
      margin-bottom: 20px;
      margin-left: 5px;
    }
    
    /* Section1b-lm small image */
    .S1b-learn-more-button {
      display: inline-block;
      padding: 8px 14px 7px 14px;
      border: 2px solid var(--secondary-color);
      border-radius: 25px;
      text-decoration: none;
      color: var(--secondary-color);
      font-family: var(--font-headers);
      transition: background-color 0.3s ease, color 0.3s ease;
      max-width: fit-content;
      font-size: .7rem;
      margin-bottom: 15%;
    }

  }
  
  @media (max-width: 460px) {
  
    .App-content-section1b {
      flex-direction: column;
    }
    
    .Sec1b-left {
    }
    
    .Section1b-img img {
      margin-top: 60px;
      margin-bottom: 60px;
      width: 280px;
    }
    
    /* Right side of Section 1b - Text */
    .Sec1b-right {
      margin-right: 50px;
      margin-left: 50px;
    }
    
    /* Title 1 */
    .Sec1b-title1 {
      font-family: var(--font-headers);
      font-size: 1.9rem;
      color: var(--secondary-color);
      margin-top: 0px;
      margin-bottom: 0px;
      font-weight: normal;
      text-align: left;
    }
    
    /* Title 2 */
    .Sec1b-title2 {
      font-family: var(--font-headers);
      font-size: 1.9rem;
      color: var(--primary-color);
      margin-bottom: 20px;
      font-weight: bold;
      text-align: left;
    }
    
    /* First text block */
    .Section1b-text1 {
      font-family: var(--font-parmedium);
      font-size: 1.1rem;
      color: var(--secondary-color);
      margin-bottom: 20px;
      line-height: 1.1;
      text-align: justify;
      margin-left: 5px;
    }
    
    /* Second text block */
    .Section1b-text2 {
      font-family: var(--font-paragraph);
      font-size: .9rem;
      color: var(--secondary-color);
      line-height: 1.05;
      text-align: justify;
      margin-bottom: 20px;
      margin-left: 5px;
    }
    
    /* Section1b-lm small image */
    .S1b-learn-more-button {
      display: inline-block;
      padding: 6px 12px 5px 12px;
      border: 2px solid var(--secondary-color);
      border-radius: 25px;
      text-decoration: none;
      color: var(--secondary-color);
      font-family: var(--font-headers);
      transition: background-color 0.3s ease, color 0.3s ease;
      max-width: fit-content;
      font-size: .6rem;
      margin-bottom: 20%;
    }

  }
  