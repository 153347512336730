.App-headerh {
  background: var(--background-color);
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease; /* Smooth transition for visibility */
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Prevent interaction when hidden */
  display: flex;
  align-items: center;
  padding-top: 7px;
}

.App-headerh.visible {
  opacity: 1; /* Fully visible when scrolled */
  pointer-events: auto; /* Allow interaction when visible */
}

.App-headerh.hidden {
  opacity: 0; /* Hide the header */
  pointer-events: none; /* Disable interaction */
}

.App-navh {
  display: flex;
  align-items: center;
  width: 100%;
}

/* App Logo - occupies 30% */
.App-logoh {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.App-logoh img {
  height: 35px;
  transition: opacity 0.3s, transform 0.3s ease;
}

.App-logoh img:hover {
  transform: scale(1.05);
}

/* App Menu - occupies 40% */
.App-menuh {
  flex: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
 }

.App-menuh li {
  margin: 0 10px;
}

.App-menuh a {
  color: var(--secondary-color, #EA0D1D); /* Set text color to primary color */
  text-decoration: none;
  font-family: var(--font-headers);
  padding: 10px 20px; /* Adjust padding as necessary for spacing */
  transition: color 0.3s, transform 0.3s;
  font-size: .65rem;
}

.App-menuh a:hover {
  color: var(--secondary-color); /* Change text color on hover */
  transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
}

/* Merch, Download, Sign In - occupies 30% */
.Merch-download-signinh {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px; /* Spacing between the items */
  padding-right: 20px;
}

.Downloadh {
  width: auto;
  height: 30px; /* Set an appropriate height for the image */
  transition: transform 0.3s ease; /* Apply hover effect */
}

.Downloadh:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.Merch-iconh {
  background-color: var(--background-color);
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.Merch-iconh:hover {
  background-color: rgba(255, 255, 255, 0.7);
  transform: scale(1.05);
}

.Signinh {
  color: var(--secondary-color); /* Set text color to primary color */
  text-decoration: underline;
  transition: transform 0.3s ease;
  font-family: var(--font-headers);
  font-size: .65em;
}

.Signinh:hover {
  transform: scale(1.05);
}

.Burger-iconh {
  display: none;
}

.Signinhh {
  display: none;
}

.Merch-link {
  display: none;
}

@media (max-width: 1000px) {
  .App-nav {
    flex-direction: row; /* Keep logo and nav items in a row */
    align-items: center; /* Center items horizontally */
    padding-top: 5px; /* Add padding to ensure content isn't too close to the top */
    padding-bottom: 5px; /* Add bottom padding for consistent spacing */
  }
  .App-menuh {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }

  .App-menuh li {
    margin: 0 1px;
  }

  .App-menuh a {
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 10px; /* Adjust padding as necessary for spacing */
    font-size: .65rem;
    }

    .App-menuh a:hover {
      transform: scale(1.85);
    }

}

/* Mobile Menu Styling */
/* Mobile Menu Styling */
@media (max-width: 768px) {

  .App-headerh {
    background: var(--background-grey);
    height: 60px;
    width: 100%;
    margin: 0;
    padding-top: 5px;
  }
  
  .App-menuh {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background: var(--background-grey);
    flex-direction: column;
    width: 100%;
    text-align: right;
    padding: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 1 !important;
    pointer-events: auto !important;
    margin: 0;
  }

  .App-menuh.open {
    display: flex;
  }

  .App-logoh {
    padding: 0;
  }

  .App-menuh li {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .Burger-iconh {
    display: block;
    margin-right: 15px;
    color: var(--secondary-color);
    font-size: 1.5rem; /* Adjust size as needed */
    cursor: pointer;
  }

  .Merch-download-signinh {
    display: none;
  }

  .App-logoh {
    margin-left: 15px;
  }
}


/* 
@media (max-width: 768px) {
  .App-headerh {
    height: auto; 
  }

  .App-logoh img {
    height: 32px;
  }

  .App-navh {
    flex-direction: row;
    align-items: center; 
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .App-menuh {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1px 0;
  }

  .App-menuh li {
    margin: 1px 0;
  }

  .App-menuh a {
    padding: 5px 10px;
    font-size: 0.75rem;
  }

  .Merch-download-signinh {
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    gap: 10px;         

  .Downloadh {
    width: auto;
    height: 25px; 
    transition: transform 0.3s ease; 
  }

  .Signinh {
    font-size: 0.65rem;
  }

  .Merch-iconh {
    margin-right: 5px; 
    font-size: 0.9rem;
  }
}


@media (max-width: 460px) {

  .App-headerh {
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
  }

  .App-logoh {
    margin-left: 15px;
  }

  .App-logoh img {
    height: 25px;
  }

  .App-navh {
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .App-menuh {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .App-menuh li {
    margin: -2px 0;
  }

  .App-menuh a {
    padding: 1px 1px;
    font-size: 0.55rem;
  }

  .Merch-download-signinh {
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    gap: 10px;
  }

  .Downloadh {
    width: auto;
    height: 15px;
    transition: transform 0.3s ease;
  }

  .Signinh {
    font-size: 0.45rem;
  }

  .Merch-iconh {
    font-size: 0.7rem;
  }
} */