/* Main container for the blog entry section */
.App-content-section1-entry2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 870px;
  position: relative;
  background: 
    linear-gradient(
      to bottom left,
      rgba(var(--background-grey-rgb), 0.5) 0%,  /* 50% transparency of secondary color starting from top right */
      rgba(var(--background-grey-rgb), 0) 50%    /* Gradually fade out to 0% opacity of secondary color by halfway */
    ),
    white;
  width: 100%;
  z-index: 0;
}

.Section1-headerb2 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  font-weight: normal;
  z-index: 2;
}
  
.App-navb2 {
  display: flex;
  align-items: center;
  width: 100%;
}

/* App Logo - occupies 30% */
.App-logob2 {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.App-logob2 img {
  height: 35px;
  transition: opacity 0.3s, transform 0.3s ease;
}

.App-logob2 img:hover {
  transform: scale(1.05);
}

/* App Menu - occupies 40% */
.App-menub2 {
  flex: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.App-menub2 li {
  margin: 0 10px;
  color: var(--secondary-color);
}

.App-menub2 a {
  color: var(--secondary-color); /* Initially white text */
  text-decoration: none;
  font-family: var(--font-headers);
  padding: 10px 20px; /* Adjust padding as necessary for spacing */
  transition: color 0.3s, transform 0.3s;
  font-size: .65rem;
}

.App-menub2 a:hover {
  color: var(--secondary-color); /* Change text color on hover */
  transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
}

/* Merch, Download, Sign In - occupies 30% */
.Merch-download-signinb2 {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px; /* Spacing between the items */
  padding-right: 20px;
}

.Downloadb2 {
  width: auto;
  height: 30px; /* Set an appropriate height for the image */
  transition: transform 0.3s ease; /* Apply hover effect */
}

.Downloadb2:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.Merch-iconb2 {
  background-color: var(--background-color);
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.Merch-iconb2:hover {
  background-color: var(--background-grey2);
  transform: scale(1.05);
}

.Signinb2 {
  color: var(--secondary-color);
  text-decoration: underline;
  transition: transform 0.3s ease;
  font-family: var(--font-headers);
  font-size: .65em;
}

.Signinb2:hover {
  transform: scale(1.05);
}

/* Background image styling */
.App-content-section1-entry2 {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* Fullscreen background image */
.Section1-entry2-background .entry2-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  z-index: -1;
  opacity: 10%;
  margin-top: 0;
  padding-top: 0;
}

.Section1-entry2-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-left: 5%;
  margin-top: -15%;
}

.Page-entry2-title {
  font-size: 1.3rem;
  font-family: var(--font-headers);
  color: var(--primary-color);
  margin-top: 0%;
}

.entry2-title-holder {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.2;
}

.entry2-feature-title {
  white-space: nowrap;
  margin-right: 15px;
  font-family: var(--font-parmedium);
  font-size: 2.2rem;
  color: var(--secondary-color);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.entry2-feature-title2 {
  flex: 1;
  font-family: var(--font-paragraph);
  font-size: 2.2rem;
  color: var(--secondary-color);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  word-break: break-word;
}

.entry2-feature-title2::after {
  display: block;
  font-size: inherit;
  text-align: left;
}

/* Author info */
.entry2-author-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.entry2-author-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}

.entry2-author-text {
  font-family: var(--font-parmedium);
  font-size: 0.9rem;
  color: var(--secondary-color);
}

/* Responsive design for different screen sizes */
@media (max-width: 1000px) {

  .App-content-Blogsection2 {
    width: 100%;
    margin: 0px auto;
  }

  /* App Menu - occupies 40% */
  .App-menub2 {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    color: var(--secondary-color);
  }

  .App-menub2 li {
    margin: 0 1px;
  }

  .App-menub2 a {
    color: var(--secondary-color); /* Initially white text */
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 10px; /* Adjust padding as necessary for spacing */
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }

  .App-menub2 a:hover {
    color: var(--secondary-color); /* Change text color on hover */
    transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
  }

  .App-content-section1-entry2 {
    max-height: 870px;
  }

  .Section1-entry2-background .entry2-image {
    top: 0;
    object-position: top; /* Aligns the image to the top */
  }

  .Section1-entry2-content {
    margin-left: 5%;
    margin-top: -30%;
  }

  .Page-entry2-title {
    font-size: 1.05rem;
    margin-top: 0%;
  }

  .entry2-title-holder {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.15;
  }

  .entry2-feature-title {
    white-space: nowrap;
    margin-right: 15px;
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }

  .entry2-feature-title2 {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }

  /* Author info */
  .entry2-author-info {
    margin-top: -20px;
  }

  .entry2-author-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .entry2-author-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {

  .Section1-headerb2 {
    height: auto;
    display: none;
  }

  .App-menub2 a {
    color: var(--secondary-color); /* Keep the color consistent */
  }

  .App-content-section1-entry2 {
    max-height: 870px;
  }

  .Section1-entry2-background .entry2-image {
    top: 50px;
    object-position: top; /* Aligns the image to the top */
  }

  .Section1-entry2-content {
    margin-left: 5%;
    margin-top: -85%;
  }

  .Page-entry2-title {
    font-size: 1rem;
    margin-top: 0%;
  }

  .entry2-title-holder {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.1;
  }

  .entry2-feature-title {
    white-space: nowrap;
    margin-right: 15px;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
  }

  .entry2-feature-title2 {
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
  }

  /* Author info */
  .entry2-author-info {
    margin-top: 0px;
  }

  .entry2-author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .entry2-author-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 460px) {

  .Section1-headerb2 {
    height: auto;
    display: none;
  }

  .App-content-section1-entry2 {
    max-height: 870px;
  }

  .Section1-entry2-background .entry2-image {
    top: 60px;
    object-position: top; /* Aligns the image to the top */
  }

  .Section1-entry2-content {
    margin-left: 5%;
    margin-top: -90%;
  }

  .Page-entry2-title {
    font-size: .75rem;
    margin-top: 0%;
  }

  .entry2-title-holder {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1;
  }

  .entry2-feature-title {
    white-space: nowrap;
    margin-right: 20px;
    font-size: 1.25rem;
    letter-spacing: 0.1rem;
  }

  .entry2-feature-title2 {
    font-size: 1.25rem;
    letter-spacing: 0.1rem;
    margin-right: 5%;
  }

  /* Author info */
  .entry2-author-info {
    margin-top: -15px;
  }

  .entry2-author-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .entry2-author-text {
    font-size: 0.8rem;
  }
}
