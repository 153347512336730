/* Main container for the section */
.App-content-Aboutsection1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 1010px;
    position: relative;
    background: var(--font-color)
  }
  

  /* IGNORE cool background color IGNORE
  background: 
  linear-gradient(
    to bottom left,
    rgba(var(--background-grey-rgb), 0.5) 0%, 
    rgba(var(--background-grey-rgb), 0) 50%
  ),
  var(--background-grey2);
width: 100%; */

  /* Section1 Header styling */
    
  .Section1-headera {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    font-weight: normal;
    background: var(--primary-color)
  }
    
  .App-nava {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  /* App Logo - occupies 30% */
  .App-logoa {
    flex: 1;
    text-align: left;
    padding-left: 20px;
  }
  
  .App-logoa img {
    height: 35px;
    transition: opacity 0.3s, transform 0.3s ease;
  }
  
  .App-logoa img:hover {
    transform: scale(1.05);
  }
  
  /* App Menu - occupies 40% */
  .App-menua {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }
  
  .App-menua li {
    margin: 0 10px;
  }
  
  .App-menua a {
    color: var(--background-color); /* Initially white text */
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 20px; /* Adjust padding as necessary for spacing */
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }
  
  .App-menua a:hover {
    color: var(--secondary-color); /* Change text color on hover */
    transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
  }
  
  /* Merch, Download, Sign In - occupies 30% */
  .Merch-download-signina {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px; /* Spacing between the items */
    padding-right: 20px;
  }
  
  .Downloada {
    width: auto;
    height: 30px; /* Set an appropriate height for the image */
    transition: transform 0.3s ease; /* Apply hover effect */
  }
  
  .Downloada:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .Merch-icona {
    background-color: var(--background-color);
    padding: 5px 10px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .Merch-icona:hover {
    background-color: rgba(255, 255, 255, 0.7);
    transform: scale(1.05);
  }
  
  .Signina {
    color: var(--font-color);
    text-decoration: underline;
    transition: transform 0.3s ease;
    font-family: var(--font-headers);
    font-size: .65em;
  }
  
  .Signina:hover {
    transform: scale(1.05);
  }
  

/* Video Container */
/* Main video section */
/* Main video section */
.About-video {
  position: relative;
  width: 90%; /* Takes up 90% of the screen width */
  margin-top: 120px;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 30px;
  margin-left: 5%;
}

.About-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* About Box and Store Buttons */
.top-info {
  position: relative;
  margin-top: -140px; /* Adjust for overlapping effect */
  text-align: center;
}

.About-s1-img {
  width: 80%;
  /* margin-bottom: 10px; */
  position: relative; /* Stacking */
  border-radius: 40px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.about-stores {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: absolute;
  top: 64%; /* Positions the buttons over the image */
  left: 50%;
  /* transform: translate(-50%, -50%); */
}

.Store-button {
  width: 18%;
}

@media (max-width: 1000px) {
  .App-content-Aboutsection1 {
    width: 100%;
    margin: 0px auto;
    min-height: 710px;
  }

  /* App Menu - occupies 40% */
  .App-menua {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }

  .App-menua li {
    margin: 0 1px;
  }

  .App-menua a {
    color: var(--font-color); /* Initially white text */
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 10px; /* Adjust padding as necessary for spacing */
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }

  .App-menua a:hover {
    color: var(--secondary-color); /* Change text color on hover */
    transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
    /* margin-top: 100px; */
  }

  .top-info {
    margin-top: 20px;
  }

}

@media (max-width: 768px) {
  
  .App-content-Aboutsection1 {
    min-height: 610px;
  }


  .Section1-headera {
    height: auto;
    display: none;
  }

  .App-logoa img {
    height: 35px;
  }

  .App-nava {
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .App-menua {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1px 0;
  }

  .App-menua li {
    margin: 1px 0;
  }

  .App-menua a {
    padding: 5px 10px;
    font-size: 0.75rem;
  }


  .Signina {
    font-size: 0.65rem;
  }


  .Merch-download-signina {
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    gap: 10px;
  }

  .Downloada {
    width: auto;
    height: 25px;
    transition: transform 0.3s ease;
  }

  .Signina {
    font-size: 0.65rem;
  }

  .Merch-icona {
    margin-right: 15px;
    font-size: 0.9rem;
  }

  .About-video {
    margin-top: 90px;
  }

  .top-info {
    margin-top: 0%;
    margin-bottom: 0%;
  }
}

@media (max-width: 460px) {

  .App-content-Aboutsection1 {
    min-height: 300px;
  }

  .Section1-headera {
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
  }

  .App-logoa {
    margin-left: 15px;
  }

  .App-logoa img {
    height: 25px;
  }

  .App-nava {
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .App-menua {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .App-menua li {
    margin: -2px 0;
  }

  .App-menua a {
    padding: 1px 1px;
    font-size: 0.55rem;
  }

  .Merch-download-signina {
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    gap: 10px;
  }

  .Downloada {
    width: auto;
    height: 15px;
    transition: transform 0.3s ease;
  }

  .Signina {
    font-size: 0.45rem;
  }

  .Merch-icona {
    font-size: 0.7rem;
  }

  .about-stores {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .Store-button {
    width: 22%;
  }

  .About-video {
    border-radius: 20px;
  }

  .About-s1-img {
    border-radius: 20px;
    margin-top: -15px;

  }

}