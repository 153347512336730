
.About-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    position: relative;
    background-color: white;
  }
  
  .About-main {
    display: flex;
    flex-direction: column;
  }
  