/* Main container for Section2 */
.section2-ourstory-container {
    display: flex;
    width: 100%;
    background-color: lightgrey;
    border-radius: 40px 40px 0 0; /* Rounded top corners */
    margin-top: -40px; /* Overlap Section1 slightly */
    /* height: auto; */
}

/* Image container */
.story-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 5%; */
}

.story-image {
    width: 50%;
    /* height: auto; */
    /* object-fit: contain; */
}

/* Text container */
.story-text-container {
    margin-top: 5%;
    margin-bottom: 5%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the text vertically */
    text-align: left;
}

.story-heading {
    font-family: var(--font-headers);
    font-size: 2rem;
    color: var(--primary-color);
    margin-top: 50px;
    margin-bottom: -10px;
    text-decoration: none;
}

.founder-name {
    font-family: var(--font-paragraph);
    font-size: 1.2rem;
    color: var(--secondary-color);
    margin-bottom: 30px;
    letter-spacing: 0.2rem;
}

.story-description {
    font-family: var(--font-parlight);
    font-size: 1rem;
    color: var(--secondary-color);
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 80%;
    text-align: justify;
}

.story-description.highlight {
    font-family: var(--font-parmedium);
    font-style: italic;
    margin-bottom: 50px;
}


@media (max-width: 1000px) {


    .section2-ourstory-container {
        border-radius: 40px 40px 0 0;
        margin-top: -180px;
    }

    /* Image container */
    .story-image-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .story-image {
        width: 50%;
    }

    .story-text-container {
        margin-top: 5%;
        margin-bottom: 5%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }

    .story-heading {
        font-size: 1.5rem;
        margin-top: 50px;
        margin-bottom: -10px;
    }

    .founder-name {
        font-size: 1rem;
        margin-bottom: 30px;
        letter-spacing: 0.2rem;
    }

    .story-description {
        font-size: .8rem;
        line-height: 1.4;
        margin-bottom: 20px;
        max-width: 80%;
    }

    .story-description.highlight {
        margin-bottom: 50px;
    }

  }

@media (max-width: 768px) {

    .section2-ourstory-container {
        border-radius: 40px 40px 0 0;
        margin-top: -200px;
    }

    /* Image container */
    .story-image-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .story-image {
        width: 70%;
    }

    .story-text-container {
        margin-top: 3%;
        margin-bottom: 3%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }

    .story-heading {
        font-size: 1.25rem;
        margin-top: 50px;
        margin-bottom: -5px;
    }

    .founder-name {
        font-size: .9rem;
        margin-bottom: 30px;
        letter-spacing: 0.2rem;
    }

    .story-description {
        font-size: .8rem;
        line-height: 1.4;
        margin-bottom: 0px;
        max-width: 80%;
    }

    .story-description.highlight {
        margin-bottom: 50px;
    }
    
}

@media (max-width: 650px) {

    .section2-ourstory-container {
        border-radius: 25px 25px 0 0;
        margin-top: -295px;
        flex-direction: column;
    }

    /* Image container */
    .story-image-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    .story-image {

        width: 40%;
    }

    .story-text-container {
        margin-top: 3%;
        margin-bottom: 3%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .story-heading {
        font-size: 1.25rem;
        margin-top: 30px;
        margin-bottom: -5px;
    }

    .founder-name {
        font-size: .9rem;
        margin-bottom: 30px;
        letter-spacing: 0.2rem;
    }

    .story-description {
        font-size: .8rem;
        line-height: 1.4;
        margin-bottom: 0px;
        max-width: 100%;
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
    }

    .story-description.highlight {
        margin-bottom: 30px;
        margin-left: 5%;
        margin-right: 5%;
        padding: 0;
    }
 

}

@media (max-width: 460px) {
  
    .section2-ourstory-container {
        border-radius: 25px 25px 0 0;
        margin-top: -345px;
        flex-direction: column;
    }

    /* Image container */
    .story-image-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    .story-image {

        width: 40%;
    }

    .story-text-container {
        margin-top: 3%;
        margin-bottom: 3%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .story-heading {
        font-size: 1.25rem;
        margin-top: 30px;
        margin-bottom: -5px;
    }

    .founder-name {
        font-size: .9rem;
        margin-bottom: 30px;
        letter-spacing: 0.2rem;
    }

    .story-description {
        font-size: .8rem;
        line-height: 1.4;
        margin-bottom: 0px;
        max-width: 100%;
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
    }

    .story-description.highlight {
        margin-bottom: 30px;
        margin-left: 5%;
        margin-right: 5%;
        padding: 0;
    }
  
}