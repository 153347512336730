/* Main container for Section5 */
.section5-about-container {
    width: 100%;
    height: auto;
    margin-bottom: -6px;
    /* padding: 0; */
    overflow: hidden; /* Ensures the video doesn't exceed the container */
}

/* Responsive iframe to maintain aspect ratio */
.section5-about-container iframe {
    position: relative;
    width: 100%;
    height: calc(100vw * 9 / 16); /* Maintains 16:9 aspect ratio */
    border: none;
}
