/* Main container for Section 2 */
.App-content-section2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--secondary-color);
  padding-top: 5%;
  padding-bottom: 5%;
}

/* Top section styling */
.Sec2-top {
  margin-top: 50px;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Sec2-bottom {
  margin-top: -4%;
}

.Sec2-top-titles {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sec2-title1, .Sec2-title2 {
  font-family: var(--font-headers);
  font-size: 3rem;
  font-weight: normal;
}

.Sec2-title1 {
  color: var(--background-color);
  margin-right: 20px;
}

.Sec2-title2 {
  color: var(--primary-color);
}

.Sec2-top-text {
  font-family: var(--font-paragraph);
  font-size: 1.15rem;
  color: var(--background-color);
  margin-top: 20px;
  margin-bottom: 10%;
  line-height: 1.25;
  text-align: justify;
  margin-left: 5px;
  max-width: 1100px;
  margin-right: 5%;
}

/* Bottom section styling */
.Sec2-bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Left section */
.Sec2-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: -1%;
}

/* Title and text container in left section */
.Sec2-left-words {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-left: 5%;
}

.S2-left-title {
  font-family: var(--font-parmedium);
  font-size: 1.25rem;
  color: var(--background-color);
  text-align: center;
  margin-bottom: 5%;
}

.S2-left-text {
  font-family: var(--font-paragraph);
  font-size: 1rem;
  color: var(--background-color);
  text-align: center;
  margin-bottom: 5%;
}

/* Image in left section */
.Section2-img1 {
  width: 30%; /* 90% of the 50% width for Sec2-left */
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
}

.Section2-img1 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Right section */
.Sec2-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

/* Title and text container in right section */
.Sec2-right-words {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%; /* 90% of the 50% width for Sec2-right */
  max-width: 90%;
  margin-right: 5%;
}

.S2-right-title {
  font-family: var(--font-parmedium);
  font-size: 1.25rem;
  color: var(--background-color);
  text-align: center;
  margin-bottom: 5%;
}

.S2-right-text {
  font-family: var(--font-paragraph);
  font-size: 1rem;
  color: var(--background-color);
  text-align: center;
  margin-bottom: 5%;
}

/* Image in right section */
.Section2-img2 {
  width: 30%; /* 90% of the 50% width for Sec2-right */
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}

.Section2-img2 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Responsive styling */
/* Responsive styling for 1000px */
@media (max-width: 1000px) {
 
  .Sec2-top {
    margin-top: 30px;
    margin-left: 50px;
    align-items: flex-start;
  }

  .Sec2-top-titles {
    flex-direction: row;
    align-items: flex-start;
  }

  .Sec2-title1 {
    margin-right: 20px;
    font-size: 2.5rem;
  }

  .Sec2-title2 {
    font-size: 2.5rem; /* Reduced by 20% */
    
  }

  .Sec2-top-text {
    font-size: 1.05rem; /* Reduced by 20% */
  }

  .S2-left-title, .S2-right-title {
    font-size: 1.05rem; /* Reduced by 20% */
  }

  .S2-left-text, .S2-right-text {
    font-size: .9rem; /* Reduced by 20% */
  }

  .Sec2-bottom {
    margin-bottom: 5%;
  }
}

/* Responsive styling for 768px */
@media (max-width: 768px) {

  .App-content-section2 {
    align-items: center;
  }

  .Sec2-top {
    margin-top: 6%;
    align-items: center;
  }

  .Sec2-top-titles {
    flex-direction: column;
    align-items: center;
  }

  .Sec2-title1, .Sec2-title2 {
    font-size: 2rem; /* Reduced by an additional 20% */
    line-height: 1.5;
  }

  .Sec2-top-text {
    font-size: .9rem; /* Reduced by an additional 20% */
    text-align: center;
    max-width: 90%;
    margin-bottom: 15%;
  }

  .Section2-img1 img {
    width: 50%;
    height: auto;
    object-fit: cover;
  }

  .Section2-img2 img {
    width: 50%;
    height: auto;
    object-fit: cover;
    margin-bottom: 5%;
  }

  .Sec2-bottom {
    flex-direction: column;
    align-items: center;
  }

  .Sec2-left, .Sec2-right {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 20px;
  }

  .Sec2-left-words, .Sec2-right-words {
    width: 100%;
    text-align: center;
    margin-bottom: 0px;
  }

  .Section2-img1, .Section2-img2 {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
  }

  .S2-left-title, .S2-right-title {
    font-size: 1.1rem; /* Reduced by an additional 20% */
    margin-bottom: 2%;
  }

  .S2-left-text, .S2-right-text {
    font-size: 0.9rem; /* Reduced by an additional 20% */
  }

  .Sec2-bottom {
    margin-bottom: 0;
  }
}
/* Responsive styling for 460px */
@media (max-width: 460px) {
  .Sec2-top {
    margin: 0 auto;
    padding-top: 5%;
    align-items: center;
  }

  .Sec2-top-titles {
    flex-direction: column;
    align-items: center;
  }

  .Sec2-title1, .Sec2-title2 {
    font-size: 2rem; /* Reduced by an additional 20% */
    line-height: 1.4;
    margin: 0;
  }

  .Sec2-top-text {
    font-size: 0.85rem; /* Reduced by an additional 20% */
    text-align: center;
    max-width: 75%;
    margin-top: 3%;
    padding: 0;
  }

  .Sec2-bottom {
    flex-direction: column;
    align-items: center;
  }

  .Sec2-left, .Sec2-right {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .Sec2-left-words, .Sec2-right-words {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .Section2-img1, .Section2-img2 {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
  }

  .S2-left-title, .S2-right-title {
    font-size: 0.9rem; /* Reduced by an additional 20% */
  }

  .S2-left-text, .S2-right-text {
    font-size: 0.8rem; /* Reduced by an additional 20% */
    width: 75%;
  }
}
