
.Blog-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.Blog-main {
  display: flex;
  flex-direction: column;
}
