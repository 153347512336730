/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2024 MyFonts Inc. */

@font-face {
  font-family: 'TrendSansOne';
  src:
    url('webFonts/TrendSansOne/font.woff2') format('woff2'),
    url('webFonts/TrendSansOne/font.woff') format('woff');
}
@font-face {
  font-family: 'GraphiqueNextComp';
  src:
    url('webFonts/GraphiqueNextComp/font.woff2') format('woff2'),
    url('webFonts/GraphiqueNextComp/font.woff') format('woff');
}
