/* Main container for the section */
.App-content-section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 510px;
  position: relative;
  background: 
    linear-gradient(
      to bottom left,
      rgba(var(--secondary-color-rgb), 0.5) 0%,  /* 50% transparency of secondary color starting from top right */
      rgba(var(--secondary-color-rgb), 0) 50%    /* Gradually fade out to 0% opacity of secondary color by halfway */
    ),
    var(--primary-color);
  width: 100%;
}


/* Section1 Header styling */
.Section1-header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  font-weight: normal;
}
  
.App-nav {
  display: flex;
  align-items: center;
  width: 100%;
}

/* App Logo - occupies 30% */
.App-logo {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.App-logo img {
  height: 35px;
  transition: opacity 0.3s, transform 0.3s ease;
}

.App-logo img:hover {
  transform: scale(1.05);
}

/* App Menu - occupies 40% */
.App-menu {
  flex: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.App-menu li {
  margin: 0 10px;
}

.App-menu a {
  color: var(--background-color); /* Initially white text */
  text-decoration: none;
  font-family: var(--font-headers);
  padding: 10px 20px; /* Adjust padding as necessary for spacing */
  transition: color 0.3s, transform 0.3s;
  font-size: .65rem;
}

.App-menu a:hover {
  color: var(--secondary-color); /* Change text color on hover */
  transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
}

/* Merch, Download, Sign In - occupies 30% */
.Merch-download-signin {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px; /* Spacing between the items */
  padding-right: 20px;
}

.Download {
  width: auto;
  height: 30px; /* Set an appropriate height for the image */
  transition: transform 0.3s ease; /* Apply hover effect */
}

.Download:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.Merch-icon {
  background-color: var(--background-color);
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.Merch-icon:hover {
  background-color: rgba(255, 255, 255, 0.7);
  transform: scale(1.05);
}

.Signin {
  color: var(--font-color);
  text-decoration: underline;
  transition: transform 0.3s ease;
  font-family: var(--font-headers);
  font-size: .65em;
}

.Signin:hover {
  transform: scale(1.05);
}

.Line1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-left: 0;
  margin-top: 90px;
  margin-bottom: 5px; /* Adjust spacing between sections */
}

.Line2 {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the left */
  width: 100%;
  margin-left: 0; /* Remove inconsistent margins */
  margin-top: 0;
  margin-bottom: 5px; /* Adjust spacing between sections */
}

.highlighted-word1 {
  font-size: 3.5rem;
  white-space: nowrap;
  color: var(--background-color);
  font-family: var(--font-headers);
  margin: 0; /* Remove any additional margin */
  padding-top: 25px;
  padding-left: 60px;
}

.highlighted-word2 {
  font-size: 3.5rem;
  white-space: nowrap;
  color: var(--secondary-color);
  font-family: var(--font-headers);
  margin: 0; /* Remove any additional margin */
  padding-left: 60px;
  padding-bottom: 0px;
  text-decoration: none;
}

.tagline {
  font-size: 1.15rem;
  color: var(--background-color);
  font-family: var(--font-paragraph);
  text-align: left;
  line-height: 1.25;
  max-width: 60%;
  margin-left: 65px; /* Remove margins for consistent alignment */
  margin-top: 30px;
}

/* Stores Section */
/* Stores Section */
.Stores {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; /* Make sure the width takes the whole container */
  margin-top: 40px; /* Adjust spacing from the tagline */
  gap: 60px; /* Space between the two images */
}

/* Apple Store and Google Store Images */
/* .Apple-Store,
.Google-Store {
  flex: 0;
} */


.Apple-hidden {
  width: 3px; /* Set appropriate width for both images */
  height: auto;
  transition: transform 0.3s ease; /* Apply hover effect */
  opacity: 0%;
}

.Apple-image {
  width: 120px; /* Set appropriate width for both images */
  height: auto;
  transition: transform 0.3s ease; /* Apply hover effect */
}

.Google-image {
  width: 134.5px; /* Set appropriate width for both images */
  height: auto;
  transition: transform 0.3s ease; /* Apply hover effect */
}

.Apple-image:hover,
.Google-image:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}


/* Image container */
.App-image-container {
  position: absolute;
  right: 60px;
  width: 350px;
  bottom: 0px;
  z-index: 1;
  top: 40px;
  transform: translateY(20%);
}

.App-image-container img {
  width: 110%;
  height: auto;
}

/* Media Query for resizing and stacking elements */
@media (max-width: 1000px) {
  .App-content-section1 {
    width: 100%;
    margin: 0px auto;
  }

  /* App Menu - occupies 40% */
  .App-menu {
    flex: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }

  .App-menu li {
    margin: 0 1px;
  }

  .App-menu a {
    color: var(--font-color); /* Initially white text */
    text-decoration: none;
    font-family: var(--font-headers);
    padding: 10px 10px; /* Adjust padding as necessary for spacing */
    transition: color 0.3s, transform 0.3s;
    font-size: .65rem;
  }

  .App-menu a:hover {
    color: var(--secondary-color); /* Change text color on hover */
    transform: scale(1.85); /* Slightly enlarge on hover for emphasis */
    /* margin-top: 100px; */
  }


  .highlighted-word1, .highlighted-word2 {
    font-size: 2.5rem;
  }


  .tagline {
    font-size: 1rem;
    max-width: 60%;
  }

  .App-image-container img {
    width: 350px;
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  
  .Section1-header {
    height: auto;
    display: none;
  }

  .App-logo img {
    height: 35px;
  }

  .App-nav {
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .App-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1px 0;
  }

  .App-menu li {
    margin: 1px 0;
  }

  .App-menu a {
    padding: 5px 10px;
    font-size: 0.75rem;
  }


  .Signin {
    font-size: 0.65rem;
  }


  .Merch-download-signin {
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    gap: 10px;
  }

  .Download {
    width: auto;
    height: 25px;
    transition: transform 0.3s ease;
  }

  .Signin {
    font-size: 0.65rem;
  }

  .Merch-icon {
    margin-right: 15px;
    font-size: 0.9rem;
  }

  .highlighted-word1 {
    padding-top: 40px;
    font-size: 2.5rem;
    margin-top: 0px;
    margin-bottom: 1px;
  }

  .highlighted-word2 {
    font-size: 2.5rem;
    margin-top: 0px;
    margin-bottom: -10px;
  }

  .tagline{
    font-size: .9rem;
    align-items: left;
    text-align: left;
    width: 50%;
  }

  .App-image-container img {
    width: 300px;
    margin-top: 80px;
    margin-left: 100px;
  }

  .Apple-Store,
  .Google-Store {
    flex: 0;
  }
  
  .Apple-image {
    width: 100px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .Google-image {
    width: 110px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .Apple-image:hover,
  .Google-image:hover {
    transform: scale(1.05);
  }
  


}

@media (max-width: 460px) {

  .App-content-section1 {
    min-height: 100px;
  }

  .Section1-header {
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
  }

  .App-logo {
    margin-left: 15px;
  }

  .App-logo img {
    height: 25px;
  }

  .App-nav {
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .App-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .App-menu li {
    margin: -2px 0;
  }

  .App-menu a {
    padding: 1px 1px;
    font-size: 0.55rem;
  }

  .Merch-download-signin {
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    gap: 10px;
  }

  .Download {
    width: auto;
    height: 15px;
    transition: transform 0.3s ease;
  }

  .Signin {
    font-size: 0.45rem;
  }

  .Merch-icon {
    font-size: 0.7rem;
  }

  .Line1 {
    margin-left: 0;
    margin-top: 30%;
    margin-bottom: 0px; /* Adjust spacing between sections */
  }
  
  .Line2 {
    margin-left: 0; /* Remove inconsistent margins */
    margin-top: 0;
    margin-bottom: 5px; /* Adjust spacing between sections */
  }

  .highlighted-word1 {
    padding-top: 0px;
    font-size: 1.9rem;
    margin-bottom: 0px;
    padding-left: 5%;
  }

  .highlighted-word2 {
    font-size: 1.9rem;
    margin-top: 0px;
    margin-bottom: -10px;
    padding-left: 5%;
  }

  .tagline{
    font-size: .8rem;
    align-items: left;
    text-align: left;
    width: 55%;
    margin-left: 5.75%;
    line-height: 1.05;
  }

  .App-image-container img {
    width: 200px;
    margin-top: 60px;
    margin-left: 48%;
    transform: rotate(-5deg);
    overflow: hidden;
  }

  .Stores {
    margin-top: 30px; /* Adjust spacing from the tagline */
    margin-left: 1%;
    gap: 4%; /* Space between the two images */
    margin-bottom: 7%
  }

  .Apple-Store,
  .Google-Store {
    flex: 0;
  }
  
  .Apple-image {
    width: 70px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .Google-image {
    width: 77px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .Apple-image:hover,
  .Google-image:hover {
    transform: scale(1.05);
  }
}