/* Main container for Section3 */
.section3-os3-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 30px;
    background-color: #f7f7f7;
    width: 100%;
    box-sizing: border-box;
}

/* Styling for content sections */
.section3-os3-content1,
.section3-os3-content2,
.section3-os3-content3 {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically center the content */
    margin-bottom: 80px; /* Adds spacing between sections */
    position: relative; /* Added relative position for the content sections */
}

/* Image and text containers - Content 1 */
.image-os3-container1 {
    flex: 1;
}

.story-os3-image1 {
    width: 60%;
    height: auto;
}

.text-os3-container1 {
    flex: 1.2;
    text-align: left;
    margin-top: 3%;
}

.story-os3-description1 {
    font-family: var(--font-parlight);
    font-size: 1rem;
    color: var(--secondary-color);
    line-height: 1.5;
    margin-bottom: 10px;
    max-width: 90%;
    text-align: justify;
}

/* Red line 1 */
.red-os3-line1 {
    position: absolute;
    width: 33%;
    height: 2px;
    background-color: var(--primary-color);
    top: 0;
    right: 0;
}

/* Image and text containers - Content 2 */
.section3-os3-content2 {
    flex-direction: row-reverse;
    align-items: center; /* Vertically center the content */
}

.image-os3-container2 {
    flex: 1;
}

.story-os3-image2 {
    width: 60%;
    height: auto;
}

.text-os3-container2 {
    margin-top: 40px;
    margin-bottom: 40px;
    flex: 1.2;
    text-align: right;
}

.story-os3-description2 {
    font-family: var(--font-parlight);
    font-size: 1rem;
    color: var(--secondary-color);
    line-height: 1.5;
    margin-bottom: 10px;
    max-width: 90%;
    text-align: justify;
    margin-left: 12%;
}

/* Red line 2 */
.red-os3-line2 {
    position: absolute;
    width: 33%;
    height: 2px;
    background-color: var(--primary-color);
    top: 0;
    left: 0;
}

/* Image and text containers - Content 3 */
.image-os3-container3 {
    flex: 1;
}

.story-os3-image3 {
    width: 60%;
    height: auto;
}

.text-os3-container3 {
    flex: 1.2;
    text-align: left;
    margin-top: 3%;
}

.story-os3-description3 {
    font-family: var(--font-parlight);
    font-size: 1rem;
    color: var(--secondary-color);
    line-height: 1.5;
    margin-bottom: 0;
    max-width: 90%;
    text-align: justify;
}

/* Red line 3 */
.red-os3-line3 {
    position: absolute;
    width: 33%;
    height: 2px;
    background-color: var(--primary-color);
    top: 0;
    right: 0;
}

@media (max-width: 1000px) {

    .section3-os3-container {
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .section3-os3-content1,
    .section3-os3-content2,
    .section3-os3-content3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        flex-direction: column;
    }

    .story-os3-image1 {
        width: 70%;
    }

    .text-os3-container1 {
        flex: 1.2;
        text-align: left;
    }

    .story-os3-description1 {
        font-size: .85rem;
        line-height: 1.3;
        margin-bottom: 0px;
        max-width: 100%;
        text-align: justify;
    }

    /* Red line 1 */
    .red-os3-line1 {
        width: 33%;
        height: 2px;
        top: -20px;
    }

    .section3-os3-content2 {
        flex-direction: column;
        align-items: center;
    }

    .story-os3-image2 {
        width: 70%;
    }

    .text-os3-container2 {
        margin-top: 40px;
        margin-bottom: 40px;
        flex: 1.2;
        text-align: right;
    }

    .story-os3-description2 {
        font-size: .85rem;
        line-height: 1.3;
        margin-bottom: 10px;
        max-width: 90%;
        text-align: justify;
        margin-left: 12%;
    }

    .red-os3-line2 {
        width: 33%;
        height: 2px;
    }

    .image-os3-container3 {
        flex: 1;
    }

    .story-os3-image3 {
        width: 70%;
        height: auto;
    }

    .text-os3-container3 {
        flex: 1.2;
        text-align: left;
    }

    .story-os3-description3 {
        font-size: .85rem;
        line-height: 1.3;
        margin-bottom: 10px;
        max-width: 90%;
        text-align: justify;
    }

    .red-os3-line3 {
        width: 33%;
        height: 2px;
    }


  }

@media (max-width: 768px) {


    .section3-os3-container {
        margin-top: 60px;
        margin-bottom: 0px;
    }

    .section3-os3-content1,
    .section3-os3-content2,
    .section3-os3-content3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    .story-os3-image1 {
        width: 75%;
    }

    .text-os3-container1 {
        flex: 1.2;
        text-align: left;
    }

    .story-os3-description1 {
        font-size: .75rem;
        line-height: 1.2;
        margin-bottom: 0px;
        max-width: 90%;
        text-align: justify;
    }

    /* Red line 1 */
    .red-os3-line1 {
        width: 33%;
        height: 2px;
        top: -20px;
    }

    .section3-os3-content2 {
        flex-direction: row-reverse;
        align-items: center;
    }

    .story-os3-image2 {
        width: 75%;
    }

    .text-os3-container2 {
        margin-top: 40px;
        margin-bottom: 40px;
        flex: 1.2;
        text-align: right;
    }

    .story-os3-description2 {
        font-size: .75rem;
        line-height: 1.2;
        margin-bottom: 10px;
        max-width: 90%;
        text-align: justify;
        margin-left: 12%;
    }

    .red-os3-line2 {
        width: 33%;
        height: 2px;
    }

    .image-os3-container3 {
        flex: 1;
    }

    .story-os3-image3 {
        width: 75%;
        height: auto;
    }

    .text-os3-container3 {
        flex: 1.2;
        text-align: left;
    }

    .story-os3-description3 {
        font-size: .75rem;
        line-height: 1.2;
        margin-bottom: 10px;
        max-width: 90%;
        text-align: justify;
    }

    .red-os3-line3 {
        width: 33%;
        height: 2px;
    }

}


@media (max-width: 650px) {


    .section3-os3-container {
        margin-top: 60px;
        margin-bottom: 0px;
    }

    /* Flex container with column direction for all content sections */
    .section3-os3-content1,
    .section3-os3-content2,
    .section3-os3-content3 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        flex-direction: column;
    }

    /* Center images */
    .story-os3-image1,
    .story-os3-image2,
    .story-os3-image3 {
        width: 60%;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
    }

    /* Center text for all containers */
    .text-os3-container1,
    .text-os3-container2,
    .text-os3-container3 {
        flex: 1.2;
        text-align: center;
        margin-left: auto; /* Remove left margin */
        margin-right: auto; /* Remove right margin */
    }

    /* Center text content and remove extra margins */
    .story-os3-description1,
    .story-os3-description2,
    .story-os3-description3 {
        font-size: .75rem;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-top: 10px;
        max-width: 90%;
        text-align: center; /* Center-align text */
        margin-left: auto;  /* Remove extra margin */
        margin-right: auto; /* Remove extra margin */
    }

    /* Red line styling for all */
    .red-os3-line1,
    .red-os3-line2,
    .red-os3-line3 {
        width: 33%;
        height: 2px;
        top: -20px;
        left: auto;
        right: auto;
        margin-bottom: 0px;
        margin-top: 0px;
        padding: 0;
    }
}


@media (max-width: 460px) {
  
    .section3-os3-container {
        margin-top: 60px;
        margin-bottom: 0px;
    }

    /* Flex container with column direction for all content sections */
    .section3-os3-content1,
    .section3-os3-content2,
    .section3-os3-content3 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        flex-direction: column;
    }

    /* Center images */
    .story-os3-image1,
    .story-os3-image2,
    .story-os3-image3 {
        width: 60%;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
    }

    /* Center text for all containers */
    .text-os3-container1,
    .text-os3-container2,
    .text-os3-container3 {
        flex: 1.2;
        text-align: center;
        margin-left: auto; /* Remove left margin */
        margin-right: auto; /* Remove right margin */
    }

    /* Center text content and remove extra margins */
    .story-os3-description1,
    .story-os3-description2,
    .story-os3-description3 {
        font-size: .75rem;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-top: 10px;
        max-width: 90%;
        text-align: center; /* Center-align text */
        margin-left: auto;  /* Remove extra margin */
        margin-right: auto; /* Remove extra margin */
    }

    /* Red line styling for all */
    .red-os3-line1,
    .red-os3-line2,
    .red-os3-line3 {
        width: 33%;
        height: 2px;
        top: -20px;
        left: auto;
        right: auto;
        margin-bottom: 0px;
        margin-top: 0px;
        padding: 0;
    }
}
