
.OurStory-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.OurStory-main {
  display: flex;
  flex-direction: column;
}
