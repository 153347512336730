/* Main container for Section 3 */
.App-content-section3 {
  /* position: relative; */
  width: 100%;
  min-height: 790px;
  background-color: var(--background-grey2);
  overflow: hidden; /* Hide overflows */
  z-index: 1; /* Base z-index to ensure Section 3 is above Section 2 */
}

/* Wrapper for Section 3 content */
.Section3-content-wrapper {
  position: relative;
  z-index: 1; /* Ensure content is above the background logo */
}

/* Background Logo Styling */
.Section3-background-logo {
  position: absolute; 
  right: 0; 
  top: 2600px;
  height: auto;
  width: 50%;
  z-index: 1; 
  opacity: 1;
}

/* Header Styling */
.Section3-header {
  margin-top: 110px;
  margin-left: 60px;
  font-family: var(--font-headers);
  font-size: 2.45rem;
  color: var(--secondary-color);
  z-index: 3; /* Ensure header is above the background logo */
  font-weight: normal;
}

/* Carousel Container */
.Section3-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 3; /* Ensure carousel content is above the background logo */
}

/* Dialogue Box Styling */
.Section3-dialogue-box {
  margin-top: 600px;
  background-color: var(--background-color);
  border-radius: 15px;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 30px;
  padding-top: 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  height: auto;
  width: 80%;
  position: absolute;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  z-index: 3; /* Ensure dialogue boxes are above background logo */
}

.Section3-dialogue-box.active {
  transform: translateX(0) scale(1.25);
  opacity: 1;
}

.Section3-dialogue-box.left {
  transform: translateX(-135%);
  opacity: 0.5;
}

.Section3-dialogue-box.right {
  transform: translateX(135%);
  opacity: 0.5;
}

.Section3-author-text {
  font-family: var(--font-paragraph);
  font-size: .95em;
  line-height: 1.25;
  text-align: justify;
}

/* Author Styling */
.Section3-author {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: -25px;
}

.Section3-author-image {
  width: auto;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
}

/* Author Details Styling */
.Section3-author-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Section3-author-name {
  font-family: var(--font-parlight);
  font-size: 1.15rem; /* Larger font size for the name */
  color: var(--secondary-color);
}

.Section3-author-title {
  font-family: var(--font-paragraph);
  font-size: 0.8rem; /* Smaller font size for the title */
  color: var(--secondary-color);
}

/* Arrow Button Styling */
.Section3-arrow-left,
.Section3-arrow-right {
  margin-top: 600px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 4; /* Ensure arrows are on top */
}

.Section3-arrow-left {
  position: absolute;
  left: 26%;
}

.Section3-arrow-right {
  position: absolute;
  right: 26%;
}

.Section3-arrow-left img,
.Section3-arrow-right img {
  width: 30px;
  height: 30px;
}

@media (max-width: 1500px) {

  /* Background Logo Styling */
  .Section3-background-logo {
    top: 2325px;
    width: 54%; /* Prevent the width from exceeding the container */
    z-index: 1; /* Higher z-index than Section 2 and Section 4, but behind other content in Section 3 */
    opacity: 1;
  }

  .Section3-arrow-left {
    position: absolute;
    left: 22%;
  }

  .Section3-arrow-right {
    position: absolute;
    right: 22%;
  }
}

@media (max-width: 1300px) {

  /* Background Logo Styling */
  .Section3-background-logo {
    top: 2250px;
    width: 60%; /* Prevent the width from exceeding the container */
    z-index: 1; /* Higher z-index than Section 2 and Section 4, but behind other content in Section 3 */
    opacity: 1;
  }

  .Section3-arrow-left {
    position: absolute;
    left: 18%;
  }

  .Section3-arrow-right {
    position: absolute;
    right: 18%;
  }
}

@media (max-width: 1150px) {

  /* Background Logo Styling */
  .Section3-background-logo {
    top: 2110px;
    width: 75%; /* Prevent the width from exceeding the container */
    z-index: 1; /* Higher z-index than Section 2 and Section 4, but behind other content in Section 3 */
    opacity: 1;
  }
}

@media (max-width: 1130px) {

  /* Background Logo Styling */
  .Section3-background-logo {
    top: 2120px;
    width: 75%; /* Prevent the width from exceeding the container */
    z-index: 1; /* Higher z-index than Section 2 and Section 4, but behind other content in Section 3 */
    opacity: 1;
  }

  .Section3-arrow-left {
    position: absolute;
    left: 13%;
  }

  .Section3-arrow-right {
    position: absolute;
    right: 13%;
  }
}

@media (max-width: 1000px) {

  /* Background Logo Styling */
  .Section3-background-logo {
    top: 1865px;
    width: 85%; /* Prevent the width from exceeding the container */
    z-index: 1; /* Higher z-index than Section 2 and Section 4, but behind other content in Section 3 */
    opacity: 1;
  }

  /* Header Styling - positioned in the upper right corner */
  .Section3-header {
    margin-top: 90px;
    margin-left: 55px;
    font-family: var(--font-headers);
    font-size: 2rem;
    color: var(--secondary-color);
    z-index: 3; /* Ensure header is above background logo */
    font-weight: normal;
  }


  .Section3-dialogue-box.active {
    transform: translateX(0) scale(1.1);
    opacity: 1;
  }

  .Section3-dialogue-box.left {
    transform: translateX(-125%);
    opacity: 0.5;
  }

  .Section3-dialogue-box.right {
    transform: translateX(125%);
    opacity: 0.5;
  }



  .Section3-arrow-left {
    position: absolute;
    left: 12%;
  }

  .Section3-arrow-right {
    position: absolute;
    right: 12%;
  }

  .Section3-arrow-left img,
  .Section3-arrow-right img {
    width: 25px;
    height: 25px;
  }

}


@media (max-width: 880px) {

  .App-content-section3 {
    min-height: 670px;
  }

  /* Background Logo Styling */
  .Section3-background-logo {
    top: 1790px;
    width: 82%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }

  .Section3-header {
    margin-top: 70px;
    margin-left: 50px;
    font-size: 1.9rem;
    font-weight: normal;
  }

  /* Dialogue Box Styling */
  .Section3-dialogue-box {
    margin-top: 500px;
    border-radius: 15px;
    padding-right: 35px;
    padding-left: 35px;
    padding-bottom: 20px;
    padding-top: 25px;
    max-width: 350px;
  }

  .Section3-dialogue-box.active {
    transform: translateX(0) scale(1.15);
  }

  .Section3-dialogue-box.left {
    transform: translateX(-125%);
  }

  .Section3-dialogue-box.right {
    transform: translateX(125%);
  }

  .Section3-author-text {
    font-size: .9em;
    line-height: 1.05;
  }

  .Section3-author {
    margin-top: 30px;
    margin-left: -25px;
  }

  .Section3-author-image {
    height: 65px;
    margin-right: 5px;
  }

  .Section3-author-name {
    font-size: 1.1rem;
  }

  .Section3-author-title {
    font-size: 0.75rem;
  }

  .Section3-arrow-left,
  .Section3-arrow-right {
    margin-top: 500px;

  }

  .Section3-arrow-left {
    left: 14%;
  }

  .Section3-arrow-right {
    right: 14%;
  }

  .Section3-arrow-left img,
  .Section3-arrow-right img {
    width: 25px;
    height: 25px;
}

}

@media (max-width: 790px) {
  .Section3-background-logo {
    top: 1815px;
    width: 85%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }
}

@media (max-width: 768px) {

  .App-content-section3 {
    min-height: 600px;
  }

  .Section3-background-logo {
    top: 2125px;
    width: 85%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }

  .Section3-header {
    margin-top: 70px;
    margin-left: 50px;
    font-size: 1.8rem;
    text-align: left;
    line-height: 1.2;
    font-weight: normal;
  }

  .Section3-dialogue-box {
    margin-top: 400px;
    border-radius: 15px;
    padding-right: 35px;
    padding-left: 35px;
    padding-bottom: 20px;
    padding-top: 25px;
    max-width: 350px;
  }

  .Section3-dialogue-box.active {
    transform: translateX(0) scale(1.15);
  }

  .Section3-dialogue-box.left {
    transform: translateX(-125%);
  }

  .Section3-dialogue-box.right {
    transform: translateX(125%);
  }

  .Section3-author-text {
    font-size: .75em;
    line-height: 1.1;
  }

  .Section3-author {
    margin-top: 20px;
    margin-left: -25px;
  }

  .Section3-author-image {
    height: 65px;
    margin-right: 3px;
  }

  .Section3-author-name {
    font-size: 1rem;
  }

  .Section3-author-title {
    font-size: 0.7rem;
  }

  .Section3-arrow-left,
  .Section3-arrow-right {
    margin-top: 400px;

  }

  .Section3-arrow-left {
    left: 7%;
  }

  .Section3-arrow-right {
    right: 7%;
  }

  .Section3-arrow-left img,
  .Section3-arrow-right img {
    width: 25px;
    height: 25px;
}

}

@media (max-width: 600px) {

  .App-content-section3 {
    min-height: 500px;
  }

  .Section3-background-logo {
    top: 2000px;
    width: 88%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }

  .Section3-header {
    margin-top: 50px;
    margin-left: 50px;
    font-size: 1.7rem;
    text-align: left;
    line-height: 1.2;
    font-weight: normal;
  }

  .Section3-dialogue-box {
    margin-top: 300px;
    border-radius: 15px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    padding-top: 15px;
    max-width: 250px;
  }

  .Section3-dialogue-box.active {
    transform: translateX(0) scale(1.15);
  }

  .Section3-dialogue-box.left {
    transform: translateX(-125%);
  }

  .Section3-dialogue-box.right {
    transform: translateX(125%);
  }

  .Section3-author-text {
    font-size: .7em;
    line-height: 1.1;
  }

  .Section3-author {
    margin-top: 15px;
    margin-left: -20px;
  }

  .Section3-author-image {
    height: 50px;
    margin-right: 2px;
  }

  .Section3-author-name {
    font-size: .85rem;
  }

  .Section3-author-title {
    font-size: 0.6rem;
  }

  .Section3-arrow-left,
  .Section3-arrow-right {
    margin-top: 300px;

  }

  .Section3-arrow-left {
    left: 9%;
  }

  .Section3-arrow-right {
    right: 9%;
  }

  .Section3-arrow-left img,
  .Section3-arrow-right img {
    width: 20px;
    height: 20px;
}

}

@media (max-width: 560px) {

  .App-content-section3 {
    min-height: 500px;
  }

  .Section3-background-logo {
    top: 2020px;
    width: 93%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }

  .Section3-header {
    margin-top: 50px;
    margin-left: 50px;
    font-size: 1.6rem;
    text-align: left;
    line-height: 1.1;
    font-weight: normal;
  }

  .Section3-dialogue-box {
    margin-top: 350px;
    border-radius: 10px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 5px;
    padding-top: 10px;
    max-width: 230px;
  }

  .Section3-dialogue-box.active {
    transform: translateX(0) scale(1.15);
  }

  .Section3-dialogue-box.left {
    transform: translateX(-125%);
  }

  .Section3-dialogue-box.right {
    transform: translateX(125%);
  }

  .Section3-author-text {
    font-size: .7em;
    line-height: 1.1;
  }

  .Section3-author {
    margin-top: 15px;
    margin-left: -20px;
  }

  .Section3-author-image {
    height: 50px;
    margin-right: 2px;
  }

  .Section3-author-name {
    font-size: .85rem;
  }

  .Section3-author-title {
    font-size: 0.6rem;
  }

  .Section3-arrow-left,
  .Section3-arrow-right {
    margin-top: 350px;

  }

  .Section3-arrow-left {
    left: 11%;
  }

  .Section3-arrow-right {
    right: 11%;
  }

  .Section3-arrow-left img,
  .Section3-arrow-right img {
    width: 20px;
    height: 20px;
}

}

@media (max-width: 460px) {

  .App-content-section3 {
    min-height: 500px;
  }

  .Section3-background-logo {
    top: 2050px;
    width: 105%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }

  .Section3-header {
    margin-top: 50px;
    margin-left: 50px;
    font-size: 1.5rem;
    text-align: left;
    line-height: 1.08;
  }

  .Section3-dialogue-box {
    margin-top: 400px;
    border-radius: 10px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 5px;
    padding-top: 10px;
    max-width: 230px;
  }

  .Section3-author-text {
    font-size: .65em;
    line-height: 1.1;
  }

  .Section3-author {
    margin-top: 15px;
    margin-left: -20px;
  }

  .Section3-author-image {
    height: 50px;
    margin-right: 2px;
  }

  .Section3-author-name {
    font-size: .85rem;
  }

  .Section3-author-title {
    font-size: 0.6rem;
  }

  .Section3-arrow-left,
  .Section3-arrow-right {
    margin-top: 400px;

  }

  .Section3-arrow-left {
    left: 6%;
  }

  .Section3-arrow-right {
    right: 6%;
  }


  
}

@media (max-width: 430px) {

  .App-content-section3 {
    min-height: 406px;
    padding: 0 0 0 0 
  }

  .Section3-background-logo {
    top: 2070px;
    width: 95%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }

  .Section3-header {
    margin-top: 50px;
    margin-left: 20px;
    font-size: 1.5rem;
    text-align: left;
    line-height: 1.08;
    max-width: 330px;
  }

  .Section3-dialogue-box {
    margin-top: 320px;
    border-radius: 10px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 5px;
    padding-top: 10px;
    max-width: 200px;
  }

  .Section3-author-text {
    font-size: .6em;
    line-height: 1.1;
  }

  .Section3-author {
    margin-top: 15px;
    margin-left: -20px;
  }

  .Section3-author-image {
    height: 50px;
    margin-right: 2px;
  }

  .Section3-author-name {
    font-size: .8rem;
  }

  .Section3-author-title {
    font-size: 0.55rem;
  }

  .Section3-arrow-left,
  .Section3-arrow-right {
    margin-top: 320px;

  }

  .Section3-arrow-left {
    left: 3%;
  }

  .Section3-arrow-right {
    right: 3%;
  }


}

@media (max-width: 350px) {

  .Section3-background-logo {
    top: 2160px;
    width: 115%; /* Prevent the width from exceeding the container */
    opacity: 1;
  }
}